import React from "react";
import Tableuser from "./tableuser";



class userTable extends React.Component {

  

    constructor(props) {
      super(props);
     
      
    }
  
   
    
    render() {
     
      

      return <Tableuser/>;
     
  }
}
  
  export default userTable;