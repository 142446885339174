import React from "react";
import LogoMain from "../../../images/logo_main.svg";
import LogoSmall from "../../../images/logo_mini.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Accordion, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';


export default class Sidebar extends React.Component{



    state = {
        isActive: false,
    };

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
        if (!this.state.isActive){
            document.querySelector(".wrapper").classList.add('more-space');
        }else{
            document.querySelector(".wrapper").classList.remove('more-space');
        }
    };

    render(){
        const isActive = this.state.isActive;
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                Hide/Show Sidebar
            </Tooltip>
        );

        return(
            <div>
                <div className={`sidebar ${isActive ? "mini-sidebar" : ""}`}>
                    <div className="sidebar-wrapper">
                        {/*<div className='text-end'>
                            <OverlayTrigger
                                placement="right"
                                delay={{ show: 200, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <Button
                                    onClick={this.handleToggle}
                                    variant='primary'
                                    className='mt-1 me-1 py-0 px-1'
                                >
                                    <FontAwesomeIcon icon={["fal", "angle-double-left"]} className={`${isActive ? "d-none" : ""}`} size="lg"/>
                                    <FontAwesomeIcon icon={["fal", "angle-double-right"]} className={`${isActive ? "" : "d-none"}`} size="lg"/>
                                </Button>
                            </OverlayTrigger>
                        </div>*/}
                        <div className='logo'>
                            <a href="#" className={`logo-main ${isActive ? "d-none" : ""}`}>
                                <img src={LogoMain} className ="logo-image w-100"/>
                            </a>
                            <a href="#" className={`logo-mini ${isActive ? "" : "d-none"}`}>
                                <img src={LogoSmall} className ="logo-image w-100"/>
                            </a>
                        </div>
                        <div className='mainnav-sidebar mt-2'>
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={["fal", "film"]} className='me-2'  size="lg"/>
                                        <span>Film</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='subnav-items'>
                                            <li>
                                                <a href="/addfilm/new">Add Film</a>
                                            </li>
                                            <li>
                                                <a href="/filmtable">Films List</a>
                                            </li>
                                            <li>
                                                <a href="/filmtableview">Films View List</a>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='mainnav-sidebar mt-2'>
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={["fal", "ticket-alt"]} className='me-2'  size="lg"/>
                                        <span>Coupon</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='subnav-items'>
                                          
                                            <li>
                                                <a href="/coupontable">Coupons List</a>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='mainnav-sidebar mt-2'>
                            <Accordion className='mb-3'>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={["fal", "users"]} className='me-2'  size="lg"/>
                                        <span>User</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ul className='subnav-items'>
                                        <li>
                                                <a href="/adduser/new">Add User</a>
                                            </li>
                                            <li>
                                                <a href="/usertable">User List</a>
                                            </li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}