import React from "react";
import Header from "../header/header";
import Sidebar from "../header/sidebar";
import Footer from "../footer/footer";

export default class MainTemplate extends React.Component{ 
  
   render(){ 
       return( 
          <div className="wrapper">
              <Sidebar />
              <div className='main-panel'>
                  <Header/>
                  <div className='content'>
                      <div className='body-content'>
                          {this.props.children}
                          <Footer/>
                      </div>
                  </div>
              </div>
         </div>
      ); 
   }
}