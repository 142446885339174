import React from 'react';
import Autosuggest from 'react-autosuggest';

import {getJWTHeaders} from "../../common/server";


class SuggestKeywords extends React.Component {
  constructor(props) {
    super(props);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
      search:this.props.search,
      idfilm:this.props.idfilm
    };
  }

 // Filter logic
 getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const options = {
        method: "GET",
        headers: getJWTHeaders()
  };
    let searchurl =process.env.REACT_APP_SERVER_URL+`/`+this.state.search+`/suggest/`+inputValue
    let response = await (await fetch(searchurl,options)).json()
    return response;
};

// Trigger suggestions
getSuggestionValue = suggestion =>  ("")

// Use your imagination to render suggestions.
renderSuggestion = suggestion => (
  <div>
    {suggestion.keyword}
  </div>
);

   // OnChange event handler
   onChange = (event, { newValue }) => {
    this.setState({
        value: newValue
    });
};

// Suggestion rerender when user types
onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value)
        .then(data => {
            if (data.Error) {
                this.setState({
                    suggestions: []
                });
            } else {
                this.setState({
                    suggestions: data
                });
            }
        })
};

  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
        suggestions: []
    });
};

onSuggestionSelected = (event, suggestion) => {
  console.log(suggestion.suggestion.keyword)
  
  console.log(suggestion.method)
 
  
 const filmkeyword = {
 
  keywords:{ idkeywords : suggestion.suggestion.idkeywords },
   film : { idfilm : this.state.idfilm}
   
 }
 
 fetch(process.env.REACT_APP_SERVER_URL+'/filmkeywords', {
   method: 'POST',
   headers: getJWTHeaders(),
   body: JSON.stringify(filmkeyword),
 }).then(data => {
   this.props.refreshKeywords()
 }
 );
 
 
 
 };
 

  render() {
    const { value, suggestions } = this.state;

    // Option props
    const inputProps = {
        placeholder: 'Type to search',
        value,
        onChange: this.onChange,
        className: 'form-control'
    };

    // Adding AutoSuggest component
    return (
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
        />
    );
}
}

export default SuggestKeywords;