import React, { Component } from 'react';
import { Redirect,BrowserRouter, Route, Switch} from 'react-router-dom';

import MainPage from "./components/mainPage";
import LoginPage from "./components/mainLogin";
import {isLogged} from "./common/server";


class App extends Component {
 
   constructor(props){
      super(props);
      this.state = {
         authed:false
      }
   }

  
 
   render() {

      function PrivateRoute ({component: Component, authed, ...rest}) {
         return (
           <Route
             {...rest}
             render={props =>
               isLoggedIn 
               ? <Component {...props} />
               : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
           />
         )
       }

       const isLoggedIn = isLogged();
 
      return (
         <BrowserRouter>
           
               <Switch>
               <Route exact path='/login' component={LoginPage}/>
               
               {/* <Route path='/' component={MainPage}/> */}
               <PrivateRoute authed={this.state.authed} path='/' component={MainPage} />   
                  
               </Switch>
           
         </BrowserRouter>
      );
   }
}
 
export default App;