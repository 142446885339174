import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import {getJWTHeaders} from "../../common/server";
import { Link } from 'react-router-dom';
import {Button, Container, Form, FormGroup, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {handleResponse} from "../../common/server";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default function Tablecoupon() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [until, setUntil] = useState(new Date());
	const [film, setFilm] = useState([]);
	
	

	let emptyFilter = {    
		page: currentPage,
		max:perPage,
		sortcol:"idcoupon",
		direction:"desc"		
	  };

	  const [currentFilter, setFilter] = useState(emptyFilter);

	  let emptyCoupon = {
    
		quantity : 1,
		name:'',
		until: null ,
		groupinfo:''
		
	  };

	  const [currentCoupon, setCurrentCoupon] = useState(emptyCoupon);


	  const deleteCoupon = (event) => {
		// console.log(event);
	 
	   
	 
		 let idcoupon = null;
		 if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
		 idcoupon = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
		 else if (event.target.attributes.getNamedItem('idtodelete'))
		 idcoupon = event.target.attributes.getNamedItem('idtodelete').value;
		 else
		 console.log(event);
		 
		 fetch(process.env.REACT_APP_SERVER_URL+'/coupon/'+idcoupon, {
		   method: 'DELETE',
		   headers: getJWTHeaders(),
		   
		 }).then(
		   data => {
			fetchCoupon(currentFilter);
		   }
		 );
	 
		 
	   }

	  

	  const handleSubmit = (event) => {
    
		event.preventDefault();
		
		console.log(event.target[0].value);

		currentCoupon.name = event.target[0].value;
		currentCoupon.groupinfo = event.target[1].value;
		currentCoupon.quantity = event.target[3].value;
		currentCoupon.idfilm = event.target[4].value;
		currentCoupon.until = until;
		
	
		fetch(process.env.REACT_APP_SERVER_URL+'/coupons/save', {
		  method: 'POST',
		  headers: getJWTHeaders(),
		  body: JSON.stringify(currentCoupon),
		}).then(handleResponse)
		  .then(function(data){
			fetchCoupon(currentFilter);
		  })
	
		
		
	  }

	const columns = [
		{
		  name: "Coupon",
		  selector: (row) => row.coupon,
		  sortable: true,
		  sortField:"coupon"
		},
		{
		  name: "Information",
		  selector: (row) => row.name,
		  sortable: true,
		  sortField:"name"
		},
		{
			name: "Group",
			selector: (row) => row.groupinfo,
			sortable: true,
			sortField:"groupinfo"
		  },
		{
		  name: "Valid until",
		  selector: (row) => row.until,
		  sortable: true,
		  right: true,
		  sortField:"until"
		},
		{
			name: "Block on film",
			selector: (row) => (row.film ? row.film.internationaltitle : "-" ),
			sortable: true,
			right: true,
			sortField:"idfilm"
		  },
		{
			name: "Used",
			selector: (row) => (row.used ? 'YES' : 'NO'),
			sortable: true,
			right: true,
			sortField:"genres.used"
		  },
		{
			name: "Action",
		  selector: (row) =>
			<div>				
				<Button size="sm" variant="danger"  onClick={deleteCoupon} idToDelete={row.idcoupon}><FontAwesomeIcon icon={["fal", "trash"]} /></Button>
		  	</div>,
		  sortable: false,
		  right: true,
		}
	  ];

	  const fetchFilm = async () => {

		const options = {
		  method: "GET",
		  headers: getJWTHeaders()
	  };
	  
	  fetch(process.env.REACT_APP_SERVER_URL+'/film/all', 
	  options
	  )
	  .then(function(response){
	  
	  console.log(response)
	  
	  return response.json();
	  
	  })
	  
	  .then(data => setFilm(data))
	  
		}

	const fetchCoupon = async currentFilter => {

		setLoading(true);

   // instead of setTimeout this is where you would handle your API call.
		const options = {
			method: "POST",
			body: JSON.stringify(currentFilter),
			headers: getJWTHeaders()
	  };
	  
	  const response = fetch(process.env.REACT_APP_SERVER_URL+'/coupons/table', 
		options
	  ).then(handleResponse)
		.then(function(data){ 
		setData(data.coupons);
		setCurrentPage(currentFilter.page);		
		setTotalRows(data.total);
		setFilter(currentFilter);
		setPerPage(currentFilter.max)
		setLoading(false);
	  }).catch(error => 
		{
			console.error(error)
			window.location = '/pleaselogin'
		})
	};

	const handleSort = (column, sortDirection ) => {
		// simulate server sort
		console.log(column, sortDirection);
		currentFilter.direction  = sortDirection;
		currentFilter.sortcol = column.sortField;
		fetchCoupon(currentFilter);

	};

	const handlePageChange = page => {
		currentFilter.page=page;
		fetchCoupon(currentFilter);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		currentFilter.page=page;
		currentFilter.max=newPerPage;
		fetchCoupon(currentFilter);
	
	};

	useEffect(() => {
		fetchCoupon(currentFilter); // fetch page 1 of users
		fetchFilm();
	}, []);

	createTheme('solarized', {
		text: {
			primary: '#fff',
			secondary: 'rgba(0,0,0,.54)',
		},
		background: {
			default: '#48486e',
		},
		context: {
			background: '#32324e',
			text: '#FFFFFF',
		},
		divider: {
			default: '#3c3c5d',
		},
		highlightOnHover: {
			default: 'rgba(124, 58, 237, 0.25)',
			text: 'rgba(255, 255, 255, 0.85)',
		},
		striped: {
			default: 'rgba(255, 255, 255, 0.05)',
			text: '#fff',
		},
	});

	return (
		<div>
			<Container fluid={true}>
				<Row>
					<Col><h1>Coupon list</h1></Col>
					<Col className='text-end'><Button variant="primary" tag={Link} href={process.env.REACT_APP_SERVER_URL + "/retrieve/coupon/export/"}>Export coupon</Button> </Col>
				</Row>
				<Form onSubmit={handleSubmit}>
				<Row>
				<Col md={2}>
            <FormGroup>
              <Form.Label for="name">Name</Form.Label>
              <Form.Control  type="text" name="name" id="name" 
                      />
            </FormGroup>
          </Col>
		  <Col md={2}>
            <FormGroup>
              <Form.Label for="name">Group</Form.Label>
              <Form.Control  type="text" name="group" id="group" 
                      />
            </FormGroup>
          </Col>
          <Col md={2}>
             <FormGroup>
              <Form.Label for="originaltitle">Valid until</Form.Label>
              <DatePicker dateFormat="dd/MM/yyyy" selected={until} onChange={(date) => setUntil(date)} className='form-control' calendarClassName="theme-style" />
            </FormGroup>
          </Col>
		  <Col md={2}>
             <FormGroup>
              <Form.Label for="originaltitle">Quantity</Form.Label>
              <Form.Control  type="text" name="quantity" id="quantity"
                     />
            </FormGroup>
          </Col>
		  <Col md={2}>
             <FormGroup>
              <Form.Label for="idfilm">Block on Film</Form.Label>
              <Form.Select type="select" name="idfilm" id="idfilm"  >
        <option value=""> -- Select a film -- </option>
            
      {film.map((cfilm) => <option value={cfilm.idfilm}>{cfilm.internationaltitle}</option>)}
        </Form.Select>
            </FormGroup>
          </Col>
		  <Col md={2}>
            <FormGroup className='mt-4'>
              <Button variant="primary" type="submit" className='w-100'>Create</Button>{' '}
             
            </FormGroup>
          </Col>
		  </Row>
		  </Form>
				<Row>
					<Col>
						<DataTable
							columns={columns}
							data={data}
							progressPending={loading}
							pagination
							paginationServer
							onSort={handleSort}
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							theme="solarized"
							highlightOnHover
							striped={true}
						/>
					</Col>
				</Row>

			</Container>
        </div>
	);

}