import React from "react";
import LogoMain from "../../images/logo_main.svg";
import {isLogged, login} from "../../common/server";

export default class Login extends React.Component{
   constructor(props){
      super(props);
      this.state = {
         username: '',
         password: ''
      }
   }

   componentDidMount() {
    
}

   changeUsername(event){
        this.setState({username:event.target.value});
   }
   changePassword(event){
        this.setState({password:event.target.value});
   }
   onSubmit(event){
        this.logged();
        event.preventDefault();
        login(this.state.username,this.state.password)
        .then(function(response){

            console.log(response.username);
          
            if(response.username){
                this.setstate = {
                    authed:true
                 }
                const { history } = this.props;
                history.push("/")
                window.location.reload(); 
            }
           
       
        }.bind(this));
        
        
   }
   
   logged(){   
       if(isLogged()) 
        this.props.history.push('/');
    
   }


   
   render(){
      return(
         <div className="h-100">
            <div className = "d-flex h-100 w-100 justify-content-center align-items-center">
                <div className='box-login'>
                    <h4 className ="mb-2">Login</h4>
                    <img src={LogoMain} className ="w-100 mb-4"/>
                    <form onSubmit={this.onSubmit.bind(this)}>
                        <div className = "form-group">
                            <input
                                type="text"
                                className = "form-control mb-3"
                                placeholder="username"
                                value = {this.state.username || ''}
                                onChange = {this.changeUsername.bind(this)}/>
                        </div>
                        <div className = "form-group">
                            <input
                                type="password"
                                className = "form-control mb-4"
                                placeholder="password"
                                value = {this.state.password}
                                onChange = {this.changePassword.bind(this)}/>
                        </div>
                        <button
                            type="submit"
                            className = "btn btn-primary pull-right"
                            >
                            Invio
                        </button>
                    </form>
                </div>
         </div>
     </div>
   );
 }
}