import React from "react";
import LogoMain from "../../images/logo_main.svg";
import { Link } from 'react-router-dom';

export default class PleaseLogin extends React.Component{
   constructor(props){
      super(props);
      this.state = {
         username: '',
         password: ''
      }
   }

   componentDidMount() {
    
}

 
   
   render(){
      return(
         <div className="h-100">
            <div className = "d-flex h-100 w-100 justify-content-center align-items-center">
                <div className='box-login'>
                    <h4 className ="mb-2">Login</h4>
                    <img src={LogoMain} className ="w-100 mb-4"/>
                   
                        <div className = "form-group">
                            Please procede to Login
                        </div>
                        <div className = "form-group">
                          <a tag={Link} href={"/login"}>Login</a>
                      </div>
                   
                </div>
         </div>
     </div>
   );
 }
}