import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getJWTHeaders, getJWTHeadersMulti} from "../../common/server";


class UploadImage extends Component {

    emptyFilm = {

        idfilm: this.props.match.params.idfilm,
        originaltitle: '',
        internationaltitle: '',
        genres: {
            idgenres: 0
        }

    };

    constructor(props) {
        super(props);
        this.state = {

            idfilm: this.props.match.params.idfilm,
            selectedFile: null,
            selectedDocument: null,
            film: this.emptyFilm,
            imagetype: null,
            imagetypes: [],
            dvdcoverimageURL: [],
            posterimageURL: [],
            headerimageURL: [],
            documentFiles: []

        };

        this.handleChange = this.handleChange.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
    }


    async componentDidMount() {
        const options = {
            method: "GET",
            headers: getJWTHeaders()
        };

        if (this.props.match.params.idfilm !== null) {

            const currentfilm = await (await fetch(process.env.REACT_APP_SERVER_URL + `/film/${this.props.match.params.idfilm}`, options)).json();
            this.setState({film: currentfilm});

            this.loadimage(options);
            this.loadDocument(options);

        }

        const imagetypes = await (await fetch(process.env.REACT_APP_SERVER_URL + `/upload/imagetype`, options)).json();
        this.setState({imagetypes: imagetypes});


    }

    async loadimage(options) {

        const mediafile = await (await fetch(process.env.REACT_APP_SERVER_URL + `/retrievefilm/${this.props.match.params.idfilm}`, options)).json();
        this.setState({
            dvdcoverimageURL: [],
            posterimageURL: [],
            headerimageURL: []
        })
        mediafile.map((data, key) => {
            if (data.mediasource === 0) {
                this.setState({dvdcoverimageURL: [...this.state.dvdcoverimageURL, process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia]})
            } else if (data.mediasource === 1) {
                this.setState({posterimageURL: [...this.state.posterimageURL, process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia]})
            } else if (data.mediasource === 2) {
                this.setState({headerimageURL: [...this.state.headerimageURL, process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia]})
            }

        });

    }

    async loadDocument(options) {
        const documentFiles = await (await fetch(process.env.REACT_APP_SERVER_URL + `/retrievefilmfiles/${this.props.match.params.idfilm}`, options)).json();
        console.log(documentFiles)
        this.setState({
            documentFiles: documentFiles
        })
    }


    // On file select (from the pop up)
    onFileChange = event => {

        // Update the state
        this.setState({selectedFile: event.target.files[0]});

    };

    onDocumentChange = event => {

        // Update the state
        this.setState({selectedDocument: event.target.files[0]});
    };

    // On file upload (click the upload button)
    onFileUpload = () => {


        if (this.state.selectedFile != null && this.state.imagetype != null) {

            // Create an object of formData
            const formData = new FormData();


            // Update the formData object
            formData.append(
                "imagefile",
                this.state.selectedFile,
                this.state.selectedFile.name
            );

            formData.append('idfilm', this.state.idfilm);

            formData.append('imagesource', this.state.imagetype);

            formData.append('target', 0);

            // Details of the uploaded file
            console.log(this.state.selectedFile);


            var requestOptions = {
                method: 'POST',
                headers: getJWTHeadersMulti(),
                body: formData,
                redirect: 'follow'
            };

            // Request made to the backend api
            // Send formData object
            fetch(process.env.REACT_APP_SERVER_URL + '/upload/image/', requestOptions)
                .then(response => response.text())
                .then(result => this.cleanform())
                .catch(error => console.log('error', error));

        } else {
            alert("Please select file and image type");
        }

    };

    onDocumentUpload = () => {


        if (this.state.selectedDocument != null) {

            // Create an object of formData
            const formData = new FormData();

            // Update the formData object
            formData.append(
                "file",
                this.state.selectedDocument,
                this.state.selectedDocument.name,
            );

            formData.append('idfilm', this.state.idfilm);

            formData.append('target', 0);

            var requestOptions = {
                method: 'POST',
                headers: getJWTHeadersMulti(),
                body: formData,
                redirect: 'follow'
            };

            // Request made to the backend api
            // Send formData object
            fetch(process.env.REACT_APP_SERVER_URL + '/upload/file/', requestOptions)
                .then(response => response.text())
                .then(result => this.cleanFormDocument())
                .catch(error => console.log('error', error));

        } else {
            alert("Please select file and image type");
        }

    };

    deleteImage(event) {
        // console.log(event);


        let idimage = null;
        if (event.target.parentElement.attributes.getNamedItem('idtodelete'))
            idimage = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
        else if (event.target.attributes.getNamedItem('idtodelete'))
            idimage = event.target.attributes.getNamedItem('idtodelete').value;
        else
            console.log(event);

        let idimagetodelete = idimage.substring(idimage.lastIndexOf("/") + 1)


        fetch(process.env.REACT_APP_SERVER_URL + '/filmimage/' + idimagetodelete, {
            method: 'DELETE',
            headers: getJWTHeaders(),

        }).then(
            data => {

                const options = {
                    method: "GET",
                    headers: getJWTHeaders()
                };

                this.loadimage(options)
            }
        );


    }

    deleteDocument(idMedia) {
        fetch(process.env.REACT_APP_SERVER_URL + '/filmfile/' + idMedia, {
            method: 'DELETE',
            headers: getJWTHeaders(),
        }).then(
            data => {
                const options = {
                    method: "GET",
                    headers: getJWTHeaders()
                };

                this.loadDocument(options)
            }
        );
    }

    downloadDocument(idMedia, fileName) {
        fetch(process.env.REACT_APP_SERVER_URL + '/retrieve/file/' + idMedia, {
            method: 'GET',
            headers: getJWTHeadersMulti(),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');//open modal to alert user
                }
                return response.blob();
            })
            .then(blob => {
                // Create a link element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger a click on the link to start the download
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            })
    }

    cleanform() {
        this.setState({imagetypedesc: ""});
        this.setState({imagetype: ""});
        this.setState({selectedFile: null});

        const options = {
            method: "GET",
            headers: getJWTHeaders()
        }

        this.loadimage(options);
    }

    cleanFormDocument() {
        this.setState({selectedDocument: null});

        const options = {
            method: "GET",
            headers: getJWTHeaders()
        }

        this.loadDocument(options)
    }

    handleChange(event) {

        const value = event.target.value;


        this.setState({imagetypedesc: event.target.options[event.target.selectedIndex].text});
        this.setState({imagetype: value});
    }

    // File content to be displayed after
    // file upload is complete
    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div className='detail-file'>
                    <p className='mb-1'>File Details:</p>
                    <p className='mb-0'><small>File Name: {this.state.selectedFile.name}</small></p>
                    <p className='mb-0'><small>File Type: {this.state.selectedFile.type}</small></p>
                </div>
            );
        } else {
            return (
                <div>
                    <p><FontAwesomeIcon icon={["fal", "exclamation-triangle"]} className='me-2'/>Choose a file and a
                        type before Pressing the 'Upload' button</p>
                </div>
            );
        }
    };

    render() {
        const film = this.state.film;
        const imagetypedesc = this.state.imagetypedesc;
        const title = <h2 className='mb-2'>File Uploads</h2>;
        const filmImage = <h4><FontAwesomeIcon icon={["fal", "film"]}
                                               className='me-2'/> {film.internationaltitle != null ? film.internationaltitle : film.originaltitle}
        </h4>;


        const dvdcovers = this.state.dvdcoverimageURL.map(imageurl => {
            return <div className='uploaded-box-image' id="dvdcover">
                <div className='d-flex justify-content-between align-items-center'><p>Dvd cover</p><Button size="sm"
                                                                                                           variant="link"
                                                                                                           onClick={this.deleteImage}
                                                                                                           idToDelete={imageurl}
                                                                                                           className='pe-0'><FontAwesomeIcon
                    icon={["fal", "trash"]}/></Button></div>
                <div><img src={imageurl} width={200} alt={"dvd cover"} className='w-100'/></div>
            </div>
        });

        const posterimages = this.state.posterimageURL.map(imageurl => {
            return <div className='uploaded-box-image' id="poster">
                <div className='d-flex justify-content-between align-items-center'><p>Poster</p><Button size="sm"
                                                                                                        variant="link"
                                                                                                        onClick={this.deleteImage}
                                                                                                        idToDelete={imageurl}
                                                                                                        className='pe-0'><FontAwesomeIcon
                    icon={["fal", "trash"]}/></Button></div>
                <div><img src={imageurl} width={200} alt={"poster"} className='w-100'/></div>
            </div>
        });

        const headerimages = this.state.headerimageURL.map(imageurl => {
            return <div className='uploaded-box-image' id="header">
                <div className='d-flex justify-content-between align-items-center'><p>Header</p><Button size="sm"
                                                                                                        variant="link"
                                                                                                        onClick={this.deleteImage}
                                                                                                        idToDelete={imageurl}
                                                                                                        className='pe-0'><FontAwesomeIcon
                    icon={["fal", "trash"]}/></Button></div>
                <div><img src={imageurl} width={200} alt={"header"} className='w-100'/></div>
            </div>
        });


        return (
            <div>
                <Container>
                    {title}
                    {filmImage}

                    <Row>
                        <Col md={6}>
                            <div>
                                <Form.Group>
                                    <Form.Label for="idimagetypes">imagetypes</Form.Label>
                                    <Form.Select name="imagetype" id="imagetype" value={this.state.imagetype}
                                                 onChange={this.handleChange} required>
                                        <option key="noselect" value=""> -- Select a type --</option>
                                        {this.state.imagetypes.map((imagetype, idimagetype) => <option key={idimagetype}
                                                                                                       value={idimagetype}>{imagetype}</option>)}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div className='d-flex align-items-center my-3'>
                                <div className="me-2">
                                    <Form.Control type="file" id="customFile" className="mb-0"
                                                  onChange={this.onFileChange} onClick={e => (e.target.value = null)}/>
                                    {/*<Form.Label className="custom-file-Form.Label" htmlFor="customFile">Choose file</Form.Label>*/}
                                </div>
                                <Button variant="primary" size='sm' className="px-3" onClick={this.onFileUpload}>
                                    Upload {imagetypedesc} !
                                </Button>
                            </div>
                            {this.fileData()}
                        </Col>
                        <Col>
                            <div className='ml-md-5'>
                                <h4 className='mb-2 mt-4'>Tips - image size</h4>
                                <div className='d-flex'>
                                    <div className='box-list-size me-3'>
                                        <p className='mb-1'>HEADER</p>
                                        <ul className='list-size'>
                                            <li>min size: 1920 x 960 px</li>
                                            <li>ratio: 2:1</li>
                                        </ul>
                                    </div>
                                    <div className='box-list-size'>
                                        <p className='mb-1'>POSTER</p>
                                        <ul className='list-size'>
                                            <li>min size: 355 x 497 px</li>
                                            <li>ratio: 5:7</li>
                                            <li><i>classic poster size (B2)</i></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4 border-top border-secondary'>
                        <Col>
                            <h6 className='mt-3'>Images uploaded</h6>
                        </Col>
                    </Row>
                    <Row md='4'>
                        <Col>
                            {dvdcovers}
                        </Col>
                        <Col>
                            {posterimages}
                        </Col>
                        <Col>
                            {headerimages}
                        </Col>
                    </Row>


                    <Row className='mt-4 border-top border-white pt-4'>
                        <Col md={6}>
                            <div>
                                <Form.Group>
                                    <Form.Label for="document">Document</Form.Label>
                                    <div className="me-2 d-flex align-items-center gap-2">
                                        <Form.Control type="file" id="document" className="mb-0 flex-shrink-1"
                                                      onChange={this.onDocumentChange}
                                                      onClick={e => (e.target.value = null)}
                                                      accept=".pdf, .doc, .xlsx"/>
                                        <Button variant="primary" size='sm' className="px-3 flex-shrink-0"
                                                onClick={this.onDocumentUpload}>
                                            Upload !
                                        </Button>
                                    </div>

                                </Form.Group>
                            </div>
                        </Col>
                        <Col className={'documents-list pt-5'}>
                            {this.state.documentFiles && this.state.documentFiles.length > 0 ?
                                this.state.documentFiles.map((document) => (
                                    <div className={'d-flex justify-content-between px-4 py-2'}>
                                        <p className={'m-0 lh-lg text-sm'}>{document.filename}</p>
                                        <div className={'d-flex align-items-center gap-2 align-items-center'}>
                                            <Button variant="primary" size="sm"
                                                    onClick={() => this.downloadDocument(document.idmedia, document.filename)}><FontAwesomeIcon
                                                icon={["fal", "download"]}/></Button>
                                            <Button variant="danger" size="sm"
                                                    onClick={() => this.deleteDocument(document.idmedia)}><FontAwesomeIcon
                                                icon={["fal", "trash"]}/></Button>
                                        </div>
                                    </div>
                                ))
                                :
                                <></>}
                        </Col>
                    </Row>


                    <Row className='mt-4'>
                        <Col>
                            <Form.Group>
                                <Button variant="primary" tag={Link} href={'/filmdetail/' + this.state.idfilm}
                                        className='px-3 me-2'>Recap Film Page</Button>
                                <Button variant="secondary" tag={Link} href="/filmtable">Back</Button>
                            </Form.Group>
                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}

export default UploadImage;
