import React from "react";
import Tablecoupon from "./tablecoupon";



class couponTable extends React.Component {

  
   
    
    render() {
     
      

      return <Tablecoupon/>;
     
  }
}
  
  export default couponTable;