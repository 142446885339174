import React from "react";
import {getJWTHeaders} from "../../common/server";
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table } from 'react-bootstrap';


class filmList extends React.Component {

  

    constructor(props) {
      super(props);
      this.state = {films: [], isLoading: true};
      
    }
  
    componentDidMount() {
     
  
      this.setState({isLoading: true});      

      const options = {
            method: "GET",
            headers: getJWTHeaders()
};
     
    fetch(process.env.REACT_APP_SERVER_URL+'/film/all/limit/10', 
        options
      )
      .then(function(response){

        console.log(response)

        return response.json();

      })

      .then(data => this.setState({films: data, isLoading: false}));
    }

   deletefilm(idfilmtodelete) {
        
           
      
    
        fetch(process.env.REACT_APP_SERVER_URL+'/film/'+idfilmtodelete, {
          method: 'DELETE',
          headers: getJWTHeaders()
        }).then() ;
       
      }
  
    
    render() {
      const {films, isLoading} = this.state;
      console.log(films);
      if (isLoading) {
         
        return <p>Loading...</p>;
      }
  
      const filmList = films.map(film => {
         
        return <tr key={film.idfilm}>
            <td>{film.idfilm}</td>
          <td style={{whiteSpace: 'nowrap'}}>{film.internationaltitle}</td>
          
          <td>{film.originaltitle}</td>
          <td>{film.year}</td>
          <td>{film.rating}</td>
          <td>{film.genres!=null && film.genres.genre}</td>
          <td>{film.show}</td>
          <td>
            <ButtonGroup>
              <Button size="sm" variant="primary" tag={Link} to={"/addfilm/" + film.idfilm}>Edit</Button>
              <Button size="sm" variant="primary" onClick={this.deletefilm.bind(this, film.idfilm)}>Delete</Button>
            </ButtonGroup>
          </td>
        </tr>
      });
  
      return (
        <div>
         
          <Container fluid>
            {/* <div className="float-right">
              <Button variant="success" tag={Link} to="/products/new">Add product</Button>
            </div> */}
            <h3>Films </h3>
            <Table className="mt-4">
              <thead>
              <tr>
              <th width="10%">Id</th>
                <th width="20%">International Title</th>                
                <th width="20%">Original Title</th>
                <th width="10%">Year</th>                
                <th width="10%">Rating</th>
                <th width="10%">Genre</th>
                <th width="10%">Show</th>
                <th width="10%">Action</th>
              </tr>
              </thead>
              <tbody>
              {filmList}
              </tbody>
            </Table>
          </Container>
          <ButtonGroup>
              <Button size="sm" variant="success" tag={Link} to={"/addfilm/new"}>Add film</Button>
            </ButtonGroup>
        </div>
      );
    }
  }
  
  export default filmList;