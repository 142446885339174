import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Form, Row,Col, Collapse } from 'react-bootstrap';
import Suggest from './suggestion';
import SuggestPerson from './suggestionperson';
import SuggestExtra from './suggestionextra';
import SuggestCountry from './suggestioncountry';
import SuggestThing from './suggestionthing';
import SuggestKeywords from './suggestionkeywords';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getJWTHeaders} from "../../common/server";


class Addfilmextra extends Component {

  emptyFilm = {
    
    idfilm: this.props.match.params.idfilm,
    originaltitle:'',
    internationaltitle: '' ,
    genres:{
      idgenres: 0
    }
    
  };

  constructor(props) {
    super(props);
    this.state = {
      item: this.emptyFilm ,
      idfilm:this.props.match.params.idfilm,
      operation:"save",
      filmkeywords:[],
      genres:[],
      people: [],
      thing: [],
      extra:[],
      filmcountry: [],
      filmlanguage: [],
      filmsubtitles: [],
      kalturafilm:[],
      director : {firstname:null,lastname:null},
      producer : {firstname:null,lastname:null,info:null},
      cast : {firstname:null,lastname:null},
      crew : {firstname:null,lastname:null,info:null},
      festival : {name:null},
      premier : {name:null},
      company : {name:null},
      openCollapseDirector: false,
      openCollapseFestival: false,
      openCollapseCompany: false,
      openCollapsePremier: false,
      openCollapseProducer: false,
      openCollapseCast: false,
      openCollapseCrew: false,
      openCollapseKeywordes: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateDirector = this.updateDirector.bind(this);
    this.updateProducer = this.updateProducer.bind(this);
    this.updateCrew = this.updateCrew.bind(this);
    this.updateCast = this.updateCast.bind(this);
    this.updateFestival = this.updateFestival.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.updatePremier = this.updatePremier.bind(this);
    this.addFestival = this.addFestival.bind(this);
    this.addPremier = this.addPremier.bind(this);
    this.addCompany = this.addCompany.bind(this);
    this.addDirector = this.addDirector.bind(this);
    this.addProducer = this.addProducer.bind(this);
    this.addCrew = this.addCrew.bind(this);
    this.addCast = this.addCast.bind(this);
    this.refreshPeople = this.refreshPeople.bind(this);
    this.refreshThing = this.refreshThing.bind(this);
    this.deletePerson = this.deletePerson.bind(this);
    this.deleteThing = this.deleteThing.bind(this);
    this.deleteExtra = this.deleteExtra.bind(this);
    this.deleteFilmcountry = this.deleteFilmcountry.bind(this);
    this.refreshCountry = this.refreshCountry.bind(this);
    this.deleteFilmlanguage = this.deleteFilmlanguage.bind(this);
    this.refreshLanguage = this.refreshLanguage.bind(this);
    this.refreshExtra = this.refreshExtra.bind(this);
    this.deleteFilmsubtitles = this.deleteFilmsubtitles.bind(this);
    this.refreshSubtitles = this.refreshSubtitles.bind(this);
    this.savePerson = this.savePerson.bind(this);
    this.saveThing = this.saveThing.bind(this);
    this.addKeyword = this.addKeyword.bind(this);
   
    this.updateKeywords = this.updateKeywords.bind(this);
    this.refreshKeywords = this.refreshKeywords.bind(this);
    this.deleteFilmkeywords = this.deleteFilmkeywords.bind(this);
  }

  async componentDidMount() {
     
    this.refreshExtra();
    this.refreshPeople();
    this.refreshCountry();
    this.refreshLanguage();
    this.refreshSubtitles();
    this.refreshThing();
    this.refreshKeywords();
  }

  refreshPeople(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmperson/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})

.then(data => this.setState({people: data}));

  }

  refreshThing(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmthing/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})

.then(data => this.setState({thing: data}));

  }

  refreshCountry(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmcountry/film/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})
.then(data => this.setState({filmcountry: data}));
  }

refreshKeywords(){

  const options = {
    method: "GET",
    headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmkeywords/film/'+this.state.idfilm, 
options
)
.then(function(response){

console.log(response)

return response.json();

})

.then(data => this.setState({filmkeywords: data}));

  }

  refreshLanguage(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmlanguage/film/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})

.then(data => this.setState({filmlanguage: data}));

  }


  refreshExtra(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmextracontent/film/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})

.then(data => this.setState({extra: data}));

  }

  refreshSubtitles(){

    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

fetch(process.env.REACT_APP_SERVER_URL+'/filmsubtitles/film/'+this.state.idfilm, 
  options
)
.then(function(response){

  console.log(response)

  return response.json();

})

.then(data => this.setState({filmsubtitles: data}));

  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let item = {...this.state.item};
    if(target.name==='idgenres'){
      item.genres.idgenres=value;
    }else{
    item[name] = value;
    } 
    this.setState({item});   
  }

  
  addDirector(){

 

    let director = {...this.state.director}

    const person = {
      firstname : director.firstname,
      lastname : director.lastname,
      role : 0,
      idfilmToadd : this.state.idfilm
    }

    this.savePerson(person)

    this.setState( {director : {firstname:'',lastname:''}});
  
  }

  addFestival(){

 

    let festival = {...this.state.festival}

    const thing = {
      name : festival.name,
      role : 0,
      idfilmToadd : this.state.idfilm
    }

    this.saveThing(thing)

    this.setState( {festival : {name:''}});

  }

  addKeyword (){

 

    let keywords = {...this.state.keywords}

    const keyword = {
      keyword : keywords.keyword,
      idfilmToadd : this.state.idfilm
    }

    fetch(process.env.REACT_APP_SERVER_URL+'/keywords', {
      method: 'POST',
      headers: getJWTHeaders(),
      body: JSON.stringify(keyword),
    }).then(
      data => {
        this.refreshKeywords()
      }
    );

    this.setState( {keywords : {keyword:''}});

  }

  addCompany(){

 

    let company = {...this.state.company}

    const thing = {
      name : company.name,
      role : 2,
      idfilmToadd : this.state.idfilm
    }

    this.saveThing(thing)

    this.setState( {company : {name:''}});

  }

  addPremier(){

 

    let premier = {...this.state.premier}

    const thing = {
      name : premier.name,
      role : 1,
      idfilmToadd : this.state.idfilm
    }

    this.saveThing(thing)

    this.setState( {premier : {name:''}});

  }

  addProducer(){

    let producer = {...this.state.producer}

    const person = {
      firstname : producer.firstname,
      lastname : producer.lastname,
      info : producer.info,
      role : 3,
      idfilmToadd : this.state.idfilm
    }

    this.savePerson(person)

    this.setState( {producer : {firstname:'',lastname:'',info:''}});

  }

  addCast(){

    let cast = {...this.state.cast}

    const person = {
      firstname : cast.firstname,
      lastname : cast.lastname,
      role : 1,
      idfilmToadd : this.state.idfilm
    }

    this.savePerson(person)

    this.setState( {cast : {firstname:'',lastname:''}});

  }

  addCrew(){

    let crew = {...this.state.crew}

    const person = {
      firstname : crew.firstname,
      lastname : crew.lastname,
      info : crew.info,
      role : 2,
      idfilmToadd : this.state.idfilm
    }

    this.savePerson(person)

    this.setState( {crew : {firstname:'',lastname:''}});

  }

  savePerson(person){

        
    fetch(process.env.REACT_APP_SERVER_URL+'/person', {
      method: 'POST',
      headers: getJWTHeaders(),
      body: JSON.stringify(person),
    }).then(
      data => {
        this.refreshPeople()
      }
    );

    
  }

  saveThing(thing){

   

    
    fetch(process.env.REACT_APP_SERVER_URL+'/thing', {
      method: 'POST',
      headers: getJWTHeaders(),
      body: JSON.stringify(thing),
    }).then(
      data => {
        this.refreshThing()
      }
    );

    
  }

  deletePerson(event){
   // console.log(event);

  
    let idfilmperson = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmperson = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmperson = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
  
    
    fetch(process.env.REACT_APP_SERVER_URL+'/filmperson/'+idfilmperson, {
      method: 'DELETE',
      headers: getJWTHeaders(),
      
    }).then(
      data => {
        this.refreshPeople()
      }
    );

    
  }

  deleteThing(event){
    // console.log(event);
 
  
     let idfilmthing = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmthing = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmthing = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
   
     
     fetch(process.env.REACT_APP_SERVER_URL+'/filmthing/'+idfilmthing, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshThing()
       }
     );
 
     
   }

  deleteFilmcountry(event){
    // console.log(event);
 
   
 
     let idfilmcountry = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmcountry = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmcountry = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
     
     fetch(process.env.REACT_APP_SERVER_URL+'/filmcountry/'+idfilmcountry, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshCountry()
       }
     );
 
     
   }

   deleteFilmkeywords(event){
    // console.log(event);
 
   
 
     let idfilmkeywords = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmkeywords = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmkeywords = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
     
     fetch(process.env.REACT_APP_SERVER_URL+'/filmkeywords/'+idfilmkeywords, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshKeywords()
       }
     );
 
     
   }

   deleteFilmlanguage(event){
    // console.log(event);
 
     
 
     let idfilmlanguage = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmlanguage = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmlanguage = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
 
     if(idfilmlanguage!=null){
     
     fetch(process.env.REACT_APP_SERVER_URL+'/filmlanguage/'+idfilmlanguage, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshLanguage()
       }
     );
      }
 
     
   }


   deleteExtra(event){
    // console.log(event);
 
     
 
     let idfilmextra = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmextra = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmextra = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
 
     if(idfilmextra!=null){
     
     fetch(process.env.REACT_APP_SERVER_URL+'/filmextracontent/'+idfilmextra, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshExtra()
       }
     );
      }
 
     
   }

   deleteFilmsubtitles(event){
    // console.log(event);
 
     let idfilmsubtitles = null;
     if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
     idfilmsubtitles = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
     else if (event.target.attributes.getNamedItem('idtodelete'))
     idfilmsubtitles = event.target.attributes.getNamedItem('idtodelete').value;
     else
     console.log(event);
 
     if(idfilmsubtitles!=null){
     fetch(process.env.REACT_APP_SERVER_URL+'/filmsubtitles/'+idfilmsubtitles, {
       method: 'DELETE',
       headers: getJWTHeaders(),
       
     }).then(
       data => {
         this.refreshSubtitles()
       }
     );
      }
     
   }

  updateDirector(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let director = {...this.state.director};
    console.log(director)
    director[name] = value;
    this.setState({
      director
    });
  }

  updateProducer(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let producer = {...this.state.producer};
    console.log(producer)
    producer[name] = value;
    this.setState({
      producer
    });
  }

  updateCast(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let cast = {...this.state.cast};
    console.log(cast)
    cast[name] = value;
    this.setState({
      cast
    });
  }

  updateFestival(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let festival = {...this.state.festival};
    console.log(festival)
    festival[name] = value;
    this.setState({
      festival
    });
  }

  updateCompany(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let company = {...this.state.company};
    console.log(company)
    company[name] = value;
    this.setState({
      company
    });
  }

  updatePremier(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let premier = {...this.state.premier};
    console.log(premier)
    premier[name] = value;
    this.setState({
      premier
    });
  }

  updateKeywords(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let keywords = {...this.state.keywords};
    console.log(keywords)
    keywords[name] = value;
    this.setState({
      keywords
    });
  }

  updateCrew(evt) {
    const target = evt.target;
    const value = target.value;
    const name = target.name;
    let crew = {...this.state.crew};
    console.log(crew)
    crew[name] = value;
    this.setState({
      crew
    });
  }

  render() {
    
    const people = this.state.people;

    const thing = this.state.thing;
    
    const title = <h2>Extra info</h2>;

    const cfilmcountry = this.state.filmcountry;

    const filmcountrylist = cfilmcountry.map(filmcountry => {
      
      
      return <li key={filmcountry.idfilmcountry}> {filmcountry.country.name}  <Button size="sm" variant="link" onClick={this.deleteFilmcountry} idToDelete={filmcountry.idfilmcountry} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>

    });

    const cfilmlanguage = this.state.filmlanguage;

    const filmlanguagelist = cfilmlanguage.map(filmlanguage => {
      
      
      return <li key={filmlanguage.idfilmlanguage}> {filmlanguage.language.name}  <Button size="sm" variant="link" onClick={this.deleteFilmlanguage} idToDelete={filmlanguage.idfilmlanguage} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>

    });

    const cfilmsubtitles = this.state.filmsubtitles;

    const filmsubtitleslist = cfilmsubtitles.map(filmsubtitles => {
      
      
      return <li key={filmsubtitles.idfilmsubtitles}> {filmsubtitles.language.name}  <Button size="sm" variant="link" onClick={this.deleteFilmsubtitles} idToDelete={filmsubtitles.idfilmsubtitles} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>

    });
    
    const directorlist = people.map( filmperson => {
      
      if(filmperson.person.role===0) 
      return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} <Button size="sm" variant="link" onClick={this.deletePerson} idToDelete={filmperson.idfilmperson} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const castlist = people.map( filmperson => {
      
      if(filmperson.person.role===1) 
      return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} <Button size="sm" variant="link" onClick={this.deletePerson} idToDelete={filmperson.idfilmperson} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const crewlist = people.map( filmperson => {
      
      if(filmperson.person.role===2) 
      return <li key={filmperson.idfilmperson}>{filmperson.person.info}: {filmperson.person.firstname} {filmperson.person.lastname} {filmperson.person.info}<Button size="sm" variant="link" onClick={this.deletePerson} idToDelete={filmperson.idfilmperson} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const producerlist = people.map( filmperson => {
      
      if(filmperson.person.role===3) 
      return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} {filmperson.person.info}<Button size="sm" variant="link" onClick={this.deletePerson} idToDelete={filmperson.idfilmperson} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const festivallist = thing.map( filmthing => {
      
      if(filmthing.thing.role===0) 
      return <li key={filmthing.idfilmthing}> {filmthing.thing.name} <Button size="sm" variant="link" onClick={this.deleteThing} idToDelete={filmthing.idfilmthing} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const companylist = thing.map( filmthing => {
      
      if(filmthing.thing.role===2) 
      return <li key={filmthing.idfilmthing}> {filmthing.thing.name} <Button size="sm" variant="link" onClick={this.deleteThing} idToDelete={filmthing.idfilmthing} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const premierlist = thing.map( filmthing => {
      
      if(filmthing.thing.role===1) 
      return <li key={filmthing.idfilmthing}> {filmthing.thing.name} <Button size="sm" variant="link" onClick={this.deleteThing} idToDelete={filmthing.idfilmthing} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

     const cfilmkeywords = this.state.filmkeywords;

    const filmkeywordslist = cfilmkeywords.map(filmkeywords => {
      
      
      return <li key={filmkeywords.idfilmkeywords}> {filmkeywords.keywords.keyword}  <Button size="sm" variant="link" onClick={this.deleteFilmkeywords} idToDelete={filmkeywords.idfilmkeywords} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>


    }); 

    const cextra = this.state.extra;

    const extrapaylist = cextra.map( extra => {
      
      if(!extra.open) 
      return <li key={extra.idfilmextracontent}> {extra.name} <Button size="sm" variant="link" onClick={this.deleteExtra} idToDelete={extra.idfilmextracontent} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });

    const extrafreelist = cextra.map( extra => {
      
      if(extra.open) 
      return <li key={extra.idfilmextracontent}> {extra.name} <Button size="sm" variant="link" onClick={this.deleteExtra} idToDelete={extra.idfilmextracontent} className='ml-1'><FontAwesomeIcon icon={["fal", "trash"]} /></Button></li>
      else
      return "";
    });


    return <div>
     
      <Container>
        {title}

      <Row form>
          <Col md={6} className='mb-5'>
            <Form.Group>
              <Form.Label for="director">Director</Form.Label>
              <ul className='people-list'>
                {directorlist}
              </ul>
              <div className='d-flex'>
                <SuggestPerson search="person" role="0" idfilm={this.state.idfilm} refreshPeople={this.refreshPeople}/>
                <Button
                    color="primary"
                    size='sm'
                    onClick={() => this.setState({ openCollapseDirector: !this.state.openCollapseDirector})}
                    aria-controls="example-collapse-text"
                    aria-expanded={this.state.openCollapseDirector}
                    className='mb-3'
                >
                  <FontAwesomeIcon icon={["fal", "user-plus"]} />
                </Button>
              </div>
            </Form.Group>

            <Collapse in={this.state.openCollapseDirector}>
              <div>
                <div className='d-flex addPersonBox'>
                  <div className='d-inline w-100 me-2'>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control name="lastname" value={this.state.director.lastname} onChange={evt => this.updateDirector(evt)}/>
                  </div>
                  <div className='d-inline w-100 me-2'>
                    <Form.Label>FirstName</Form.Label>
                    <Form.Control name="firstname" value={this.state.director.firstname} onChange={evt => this.updateDirector(evt)}/>
                  </div>
                  <Button color="primary" size='sm' onClick={this.addDirector} className='mt-auto py-1'>ADD</Button>
                </div>
              </div>
            </Collapse>

          </Col>


          <Col md={6} className='mb-5'>
            <Form.Group>
              <Form.Label for="producer">Producer</Form.Label>
              <ul className='people-list'>
                {producerlist}
              </ul>
              <div className='d-flex'>
                <SuggestPerson search="person" role="3" idfilm={this.state.idfilm} refreshPeople={this.refreshPeople}/>
                <Button
                  color="primary"
                  size='sm'
                  onClick={() => this.setState({ openCollapseProducer: !this.state.openCollapseProducer})}
                  aria-controls="example-collapse-text"
                  aria-expanded={this.state.openCollapseProducer}
                  className='mb-3'
                >
                  <FontAwesomeIcon icon={["fal", "user-plus"]} />
                </Button>
              </div>
            </Form.Group>
            <Collapse  in={this.state.openCollapseProducer}>
              <div>
                <div className='d-flex addPersonBox'>
                  <div className='d-inline w-100 me-2'>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control name="lastname" value={this.state.producer.lastname} onChange={evt => this.updateProducer(evt)}/>
                  </div>
                  <div className='d-inline w-100 me-2'>
                    <Form.Label>FirstName</Form.Label>
                    <Form.Control name="firstname" value={this.state.producer.firstname} onChange={evt => this.updateProducer(evt)}/>
                  </div>
                  <div className='d-inline w-100 me-1'>
                 <Form.Label>Company</Form.Label>
                 <Form.Control name="info" value={this.state.producer.info} onChange={evt => this.updateProducer(evt)}/>
               </div>
                  <Button color="primary" size='sm' onClick={this.addProducer} className='mt-auto py-1'>ADD</Button>
                </div>
              </div>
            </Collapse>
          </Col>
      </Row>

      
      <Row form>
        <Col md={6} className='mb-5'>
         
         <Form.Group>
           <Form.Label for="cast">Cast</Form.Label>
           <ul className='people-list'>
             {castlist}
           </ul>
           <div className='d-flex'>
             <SuggestPerson search="person" role="1" idfilm={this.state.idfilm} refreshPeople={this.refreshPeople}/>
             <Button
                 color="primary"
                 size='sm'
                 onClick={() => this.setState({ openCollapseCast: !this.state.openCollapseCast})}
                 aria-controls="example-collapse-text"
                 aria-expanded={this.state.openCollapseCast}
                 className='mb-3'
             >
               <FontAwesomeIcon icon={["fal", "user-plus"]} />
             </Button>
           </div>
         </Form.Group>
          <Collapse in={this.state.openCollapseCast}>
            <div>
              <div className='d-flex addPersonBox'>
                <div className='d-inline w-100 me-2'>
                  <Form.Label>LastName</Form.Label>
                  <Form.Control name="lastname" value={this.state.cast.lastname} onChange={evt => this.updateCast(evt)}/>
                </div>
                <div className='d-inline w-100 me-2'>
                  <Form.Label>FirstName</Form.Label>
                  <Form.Control name="firstname" value={this.state.cast.firstname} onChange={evt => this.updateCast(evt)}/>
                </div>
                
                <Button color="primary" size='sm' onClick={this.addCast} className='mt-auto py-1'>ADD</Button>
              </div>
            </div>
          </Collapse>
       </Col>

       <Col md={6} className='mb-5'>
         <Form.Group>
           <Form.Label for="director">Crew</Form.Label>
           <ul className='people-list'>
             {crewlist}
           </ul>
           <div className='d-flex'>
             <SuggestPerson search="person" role="2" idfilm={this.state.idfilm} refreshPeople={this.refreshPeople}/>
             <Button
                 color="primary"
                 size='sm'
                 onClick={() => this.setState({ openCollapseCrew: !this.state.openCollapseCrew})}
                 aria-controls="example-collapse-text"
                 aria-expanded={this.state.openCollapseCrew}
                 className='mb-3'
             >
               <FontAwesomeIcon icon={["fal", "user-plus"]} />
             </Button>
           </div>
         </Form.Group>
         <Collapse in={this.state.openCollapseCrew}>
           <div>
             <div className='d-flex addPersonBox'>
               <div className='d-inline w-100 me-2'>
                 <Form.Label>LastName</Form.Label>
                 <Form.Control name="lastname" value={this.state.crew.lastname} onChange={evt => this.updateCrew(evt)}/>
               </div>
               <div className='d-inline w-100 me-2'>
                 <Form.Label>FirstName</Form.Label>
                 <Form.Control name="firstname" value={this.state.crew.firstname} onChange={evt => this.updateCrew(evt)}/>
               </div>
               <div className='d-inline w-100 me-1'>
                 <Form.Label>Role</Form.Label>
                 <Form.Control name="info" value={this.state.crew.info} onChange={evt => this.updateCrew(evt)}/>
               </div>
               <Button color="primary" size='sm' onClick={this.addCrew} className='mt-auto py-1'>ADD</Button>
             </div>
           </div>
         </Collapse>
       </Col>
      </Row>

      <Row form>
      <Col md={6} className='mb-5'>
         
         <Form.Group>
           <Form.Label for="company">Company</Form.Label>
           <ul className='people-list'>
            {companylist}
          </ul>
           <div className='d-flex'>
             <SuggestThing search="thing" role="2" idfilm={this.state.idfilm}  refreshThing={this.refreshThing}/>
             <Button
                 color="primary"
                 size='sm'
                 onClick={() => this.setState({ openCollapseCompany: !this.state.openCollapseCompany})}
                 aria-controls="example-collapse-text"
                 aria-expanded={this.state.openCollapseCompany}
                 className='mb-3'
             >
               <FontAwesomeIcon icon={["fal", "user-plus"]} />
             </Button>
           </div>
        </Form.Group>
        <Collapse in={this.state.openCollapseCompany}>
          <div>
            <div className='d-flex addPersonBox'>
              <div className='d-inline w-100 me-2'>
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" value={this.state.company.name} onChange={evt => this.updateCompany(evt)}/>
              </div>
              <Button color="primary" size='sm' onClick={this.addCompany} className='mt-auto py-1'>ADD</Button>
            </div>
          </div>
        </Collapse>
      </Col>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label for="director">Festival</Form.Label>
            <ul className='people-list'>
             {festivallist}
           </ul>
            <div className='d-flex'>
              <SuggestThing search="thing" role="0" idfilm={this.state.idfilm}  refreshThing={this.refreshThing}/>
              <Button
                  color="primary"
                  size='sm'
                  onClick={() => this.setState({ openCollapseFestival: !this.state.openCollapseFestival})}
                  aria-controls="example-collapse-text"
                  aria-expanded={this.state.openCollapseFestival}
                  className='mb-3'
              >
                <FontAwesomeIcon icon={["fal", "user-plus"]} />
              </Button>
            </div>
         </Form.Group>
         <Collapse in={this.state.openCollapseFestival}>
           <div>
             <div className='d-flex addPersonBox'>
               <div className='d-inline w-100 me-2'>
                 <Form.Label>Name</Form.Label>
                 <Form.Control name="name" value={this.state.festival.name} onChange={evt => this.updateFestival(evt)}/>
               </div>
               <Button color="primary" size='sm' onClick={this.addFestival} className='mt-auto py-1'>ADD</Button>
             </div>
           </div>
         </Collapse>
       </Col>
      
       
      </Row>

      
      <Row form>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label for="premier">Premier</Form.Label>
            <ul className='people-list'>
             {premierlist}
           </ul>
            <div className='d-flex'>
              <SuggestThing search="thing" role="1" idfilm={this.state.idfilm}  refreshThing={this.refreshThing}/>
              <Button
                  color="primary"
                  size='sm'
                  onClick={() => this.setState({ openCollapsePremier: !this.state.openCollapsePremier})}
                  aria-controls="example-collapse-text"
                  aria-expanded={this.state.openCollapsePremier}
                  className='mb-3'
              >
                <FontAwesomeIcon icon={["fal", "user-plus"]} />
              </Button>
            </div>
         </Form.Group>
         <Collapse in={this.state.openCollapsePremier}>
           <div>
             <div className='d-flex addPersonBox'>
               <div className='d-inline w-100 me-2'>
                 <Form.Label>Name</Form.Label>
                 <Form.Control name="name" value={this.state.premier.name} onChange={evt => this.updatePremier(evt)}/>
               </div>
               <Button color="primary" size='sm' onClick={this.addPremier} className='mt-auto py-1'>ADD</Button>
             </div>
           </div>
         </Collapse>
       </Col>
      

       <Col md={6} className='mb-5'>
           <Form.Group>
           <Form.Label for="subtitles">Subtitles</Form.Label>
             <ul className='people-list'>
               {filmsubtitleslist}
             </ul>
            <Suggest search="language" idfilm={this.state.idfilm} type="1"  refreshLanguage={this.refreshLanguage}  refreshSubtitles={this.refreshSubtitles}/>
          </Form.Group>        
          </Col>

       
      </Row>

      <Row form>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label for="language">Language</Form.Label>
            <ul className='people-list'>
              {filmlanguagelist}
            </ul>
            <Suggest search="language" idfilm={this.state.idfilm} type="0" refreshLanguage={this.refreshLanguage} refreshSubtitles={this.refreshSubtitles}/>
          </Form.Group>    
          </Col>
        <Col md={6} className='mb-5'>
           <Form.Group>
           <Form.Label for="countries">Country</Form.Label>
             <ul className='people-list'>
               {filmcountrylist}
             </ul>
            <SuggestCountry search="countries" idfilm={this.state.idfilm} refreshCountry={this.refreshCountry}/>
          </Form.Group>        
          </Col>
      </Row>
      <Row form>
      <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label for="company">Keywords</Form.Label>
            <ul className='people-list'>
             {filmkeywordslist} 
           </ul>
            <div className='d-flex'>
              <SuggestKeywords idfilm={this.state.idfilm} search="keywords" refreshKeywords={this.refreshKeywords}/>
              <Button
                  color="primary"
                  size='sm'
                  onClick={() => this.setState({ openCollapseKeywords: !this.state.openCollapseKeywords})}
                  aria-controls="example-collapse-text"
                  aria-expanded={this.state.openCollapseKeywords}
                  className='mb-3'
              >
                <FontAwesomeIcon icon={["fal", "user-plus"]} />
              </Button>
            </div>
         </Form.Group>
         <Collapse in={this.state.openCollapseKeywords}>
           <div>
             <div className='d-flex addPersonBox'>
               <div className='d-inline w-100 me-2'>
                 <Form.Label>Name</Form.Label>
                 <Form.Control name="keyword" value={this.state.filmkeywords.keyword} onChange={evt => this.updateKeywords(evt)}/>
               </div>
               <Button color="primary" size='sm' onClick={this.addKeyword} className='mt-auto py-1'>ADD</Button>
             </div>
           </div>
         </Collapse>
       </Col>
      </Row>


      <Row form>
        <Col md={6} className='mb-5'>
          <Form.Group>
            <Form.Label for="extrafree">Extra content free</Form.Label>
            <ul className='people-list'>
              {extrafreelist}
            </ul>
            <SuggestExtra search="extra" idfilm={this.state.idfilm} open="true" refreshExtra={this.refreshExtra} />
          </Form.Group>    
          </Col>
        <Col md={6} className='mb-5'>
           <Form.Group>
           <Form.Label for="extrapay">Extra content pay</Form.Label>
             <ul className='people-list'>
               {extrapaylist}
             </ul>
            <SuggestExtra search="countries" idfilm={this.state.idfilm} open="false" refreshExtra={this.refreshExtra}/>
          </Form.Group>        
          </Col>
      </Row>

      <Row form>
          <Col md={6} className='mb-5'>
            <Form.Group>
              <Button variant="primary" tag={Link} href={'/filmdetail/'+this.state.idfilm} className='me-2 px-5'>Recap Film Page</Button>
              <Button variant="secondary" tag={Link} href="/filmtable">Back</Button>
            </Form.Group>
          </Col>
        </Row>
     
      </Container>
    </div>
  }
}

export default Addfilmextra;