import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Button, Container, Form, FormGroup, Row, Col, Tab, Nav} from 'react-bootstrap';
import {getJWTHeaders} from "../../common/server";
import SuggestCountry from '../film/suggestioncountry';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";



class Adduser extends Component {


  emptyUser = {
    
    iduser: this.props.match.params.iduser,
    surname:'',
    name: '' ,
    admin :0,
    enable:false,
    countries:{
      code: "CH"
    }
    
  };

  constructor(props) {
    super(props);
    this.state = {
      user: this.emptyUser ,
      iduser:this.props.match.params.iduser,
      operation:"save",
      countrylist :[],
      activeTab: '1'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
   
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }


  async componentDidMount() {
    const options = {
      method: "GET",
      headers: getJWTHeaders()
};

    if (this.props.match.params.iduser !== 'new') {

        

      const currentuser = await (await fetch(process.env.REACT_APP_SERVER_URL+`/streaminguser/${this.props.match.params.iduser}`,options)).json();
      this.setState({user: currentuser,
                    operation:"update" });
                  
    }else{
      let user = {...this.state.user};
    user["iduser"] = null;     
      this.setState({user,operation:"save" });
    }


    const countrylist = await (await fetch(process.env.REACT_APP_SERVER_URL+`/countries`,options)).json();
    this.setState({countrylist:countrylist });

   
  }

  handleChange(event) {
    console.log(event)
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let user = {...this.state.user};
    
    user[name] = value;
   
    this.setState({user});   
    
  }

  handleChangeEditor(html,currentId) {
    console.log(html);
    console.log(currentId);
  
    const value = html;
    const name =  currentId;
    let user = {...this.state.user};
    
    user[name] = value;
    
    this.setState({user});    
  }

  async handleSubmit(event) {
    
    event.preventDefault();
    const user = this.state.user;
    
  
    

    await fetch(process.env.REACT_APP_SERVER_URL+'/streaminguser/', {
      method: 'POST',
      headers: getJWTHeaders(),
      body: JSON.stringify(user),
    })

   
    this.props.history.push('/usertable')
    
  }



  render() {
    const {user} = this.state;
    const title = <h2>{user.iduser ? 'Edit User' : 'Add User'} </h2>;

    const {countrylist} = this.state;

    

    return <div>
     
      <Container fluid="xl">
        {title}

      <Form onSubmit={this.handleSubmit}>

        <Row form>
          <Col md={6}>
            <FormGroup>
              <Form.Label for="name">Name</Form.Label>
              <Form.Control  type="text" name="name" id="name" value={user.name || ''}
                     onChange={this.handleChange} />
              </FormGroup>
          </Col>
          <Col md={6}>
             <FormGroup>
              <Form.Label for="surname">Surname</Form.Label>
              <Form.Control  type="text" name="surname" id="surname" value={user.surname || ''}
                     onChange={this.handleChange} />
            </FormGroup>
          </Col>
        </Row>

      <Row form>
        <Col md={6}>

          <FormGroup>
            <Form.Label for="email">Email</Form.Label>
            <Form.Control  type="text" name="email" id="email" value={user.email || ''}
                   onChange={this.handleChange} />
          </FormGroup>
          </Col>
        <Col md={6}>
          <FormGroup>
            <Form.Label for="dateofbirth">Date of birth</Form.Label>
            <Form.Control type="date" name="dateofbirth" id="dateofbirth" value={user.dateofbirth || new Date()}
                   onChange={this.handleChange} />
          </FormGroup>  
          </Col>
      </Row>

        <Row form>
          <Col md={5}>
            <Form.Group>
              <Form.Label for="countries">Country</Form.Label>
              <Form.Select type="select" name="countries" id="countries" value={user.countries.code || ''} onChange={this.handleChange} required>
                <option value="" disabled> -- Select a coutry -- </option>
                {countrylist.map((country) => <option value={country.code}>{country.name}</option>)}

              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={4}>
          <Form.Group>
              <Form.Label for="role">Role</Form.Label>
              <Form.Select type="select" name="admin" id="admin" value={user.admin || '0'} onChange={this.handleChange} required>
                <option value="" disabled> -- Select a role -- </option>
                <option value={0}>User</option>
                <option value={1}>Press</option>
                <option value={2}>Supporter</option>
                <option value={3}>Staff</option>
              </Form.Select>
            </Form.Group>
           
          </Col>
          <Col md={3}>
            
            <Form.Group>
              <Form.Label for="role">Status</Form.Label>
              <Form.Select type="select" name="enable" id="enable" value={user.enable || 'false'} onChange={this.handleChange} required>
                <option value="" disabled> -- Select a status -- </option>
                <option value={true}>Enable</option>
                <option value={false}>Disable</option>               
              </Form.Select>
            </Form.Group>
             
          </Col>
        </Row>


        <Row form>
          <Col md={6}>
            <FormGroup className='mt-4'>
              <Button variant="primary" type="submit" className='me-2 w-25'>Save</Button>
              <Button variant="secondary" tag={Link} href="/usertable">Cancel</Button>
            </FormGroup>
          </Col>
        </Row>

        </Form>
      </Container>
    </div>
  }
}

export default Adduser;