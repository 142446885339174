import React from "react";
import { Container, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class Home extends React.Component{ 
   constructor(props){ 
      super(props); 
   }
   render(){ 
      return( 
         <div>
            <Container>
               <Row>
                  <Col>
                     <h1>Benvenuto nel backend di Castellinaria!</h1>
                  </Col>
               </Row>
               <Row className='bg-group-home mb-4'>
                  <Col sm={12}><h5 className='mb-3'>Films</h5></Col>
                  <Col md={4}>
                     <a className='box-group-home' href="/filmtable">
                        <FontAwesomeIcon icon={["fal", "film"]} className='icon' />
                        <span>List Film</span>
                     </a>
                  </Col>
                  <Col md={4}>
                     <a className='box-group-home' href="/addfilm/new">
                        <FontAwesomeIcon icon={["fal", "photo-video"]} className='icon'/>
                        <span>Add Film</span>
                     </a>
                  </Col>
               </Row>
            </Container>
         </div>
      ); 
   } 
}