import React from "react";
import Footer from "../footer/footer";


export default class LoginTemplate extends React.Component{ 

   render(){ 
       return( 
          <div className="wrapper login-wrapper">
              <div className='main-panel h-100 pb-3'>
                  <div className='d-flex h-100 flex-column'>
                          {this.props.children}
                      <div className='mt-auto'>
                          <Footer/>
                      </div>
                  </div>
              </div>
         </div>
      ); 
   }
}