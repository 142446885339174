import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default class Footer extends React.Component{ 
   constructor(props){ 
      super(props); 
   }
   render(){ 
      return( 
         <div className = "container-fluid footer">
            <div className = "row mt-5" >
               <div className = "col">
                  <div className="d-flex justify-content-between align-items-center">
                     <p className='fa-sm mb-0'>Castellinaria | Via Cattori 3 | CH-6502 Bellinzona | Switzerland | <a href='mailto:segretariato@castellinaria.ch'>@castellinaria.ch</a></p>
                     <div>
                        <a href="https://www.facebook.com/castellinariafestival/" target="_blank">
                           <FontAwesomeIcon icon={["fab", "facebook"]} className='mx-1'/>
                        </a>
                        <a href="https://twitter.com/Castellinaria1" target="_blank">
                           <FontAwesomeIcon icon={["fab", "twitter"]} className='mx-1'/>
                        </a>
                        <a href="https://www.instagram.com/castellinariafestival/" target="_blank">
                           <FontAwesomeIcon icon={["fab", "instagram"]} className='mx-1'/>
                        </a>
                        <a href="https://www.youtube.com/channel/UC4z-SRNxzLDN81oNuFqxZUQ" target="_blank">
                           <FontAwesomeIcon icon={["fab", "youtube"]} className='mx-1'/>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      ); 
   }
}