import { Cookies} from 'react-cookie'

export const login  = async (username,password) => {

   

    const config = {
       
        
            username: username,
            password: password
         
      };
  
      return fetch(process.env.REACT_APP_SERVER_URL+'/public/login', {
        method: 'POST',
        headers: getJWTHeaders(),
        body: JSON.stringify(config),
      })
      .then(handleLoginResponse)
    .then(function(response){

        console.log(response);

    if(response.username!=null){
   
    

   
    console.log(getJWTHeaders());
    localStorage.setItem('logged', true);
     
    } else {

        localStorage.setItem('logged', false);
        
    }
    return response;
});

}

export function saveJWT(response){
    const userToken = response.headers.get('Authorization');
    console.log(userToken)
    const cookies = new Cookies();
    cookies.set('jwt_token', userToken, {
        expires: new Date( Date.now() + 16 * 3600 * 1000),
        path: '/',
        
    })
}

export function getJWTHeaders(){

     let headers =  {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
      };

    const cookies = new Cookies();
    
    headers['Authorization'] = cookies.get('jwt_token');
    return headers;
}

export function getJWTHeadersMulti(){

    let headers =  {
       'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*",
       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
     };

   const cookies = new Cookies();
   
   headers['Authorization'] = cookies.get('jwt_token');
   return headers;
}

export function deleteJWT(){

  

   const cookies = new Cookies();
   
    cookies.remove('jwt_token');
}


export function isLogged(){

    if(localStorage.getItem('logged'))
    return true;
else
    return false;
}

export function handleLoginResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.setItem('logged', false);
                console.log("logout if 401 Unauthorized or 403 Forbidden response returned from api")
               // authenticationService.logout();
                
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }else{
            saveJWT(response)
        }

        return data;
    });

}


export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                localStorage.setItem('logged', false);
                console.log("logout if 401 Unauthorized or 403 Forbidden response returned from api")
               // authenticationService.logout();
                
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });

}