import React from "react";
import { getJWTHeaders } from "../../common/server";

import { Container, Row, Col } from "react-bootstrap";

export default class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      window: window,
      film: undefined,
      idfilm: this.props.match.params.idfilm,
      loadMedia: false,
      ks: undefined,
      playerConfig: {
        targetId: "kaltura_player_aaa",
        bundlerUrl: "https://cdnapisec.kaltura.com",
        provider: {
          partnerId: "4314013",
          uiConfId: "48816163",
          ks: undefined,
        },
        playback: {
          autoplay: "false",
        },
      },
    };
  }

  componentDidMount() {
    this.loadFilmAndSession();
  }

  componentDidUpdate(prevProps) {
    if (this.state.loadMedia) {
      this.initPlayer();
      this.loadPlayer();
      this.setState({ loadMedia: false });
    }
  }

  async loadFilmAndSession() {
    const options = {
      method: "GET",
      headers: getJWTHeaders(),
    };
    fetch(
      process.env.REACT_APP_SERVER_URL + `/admin/play/${this.state.idfilm}`,
      options
    ).then((fetchResponse) => fetchResponse.json())
      .then((response) => {
        this.setState({
          ks: response.kalturatoken,
          film: response.film,
          loadMedia: true,
        });
      });
  }

  initPlayer() {
    let playerConfig = this.state.playerConfig;
    playerConfig.provider.ks = this.state.ks;
    this.state.window.initKaltura(playerConfig);
  }

  loadPlayer() {
    let kalturaPlayer = this.state.window.kalturaPlayer;
    kalturaPlayer.loadMedia({
      entryId: this.state.film?.kalturaid,
    }).then(() => {
      kalturaPlayer.play();
    });
  }

  render() {
    return (
      <div className="App">
        <Row>
          <h1>Preview - {this.state.film?.internationaltitle}</h1>
        </Row>
        <Row>
          <Col>
            <div
              id="kaltura_player_aaa"
              style={{ width: "auto", height: "40em" }}
            ></div>
          </Col>
        </Row>
      </div>
    );
  }
}
