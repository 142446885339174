import React from "react";
import Tablefilm from "./tablefilm";



class filmTable extends React.Component {

  

    constructor(props) {
      super(props);
     
      
    }
  
   
    
    render() {
     
      

      return <Tablefilm/>;
     
  }
}
  
  export default filmTable;