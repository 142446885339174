import React from "react";
import Tablefilmview from "./tablefilmview";



class filmviewTable extends React.Component {

  

    constructor(props) {
      super(props);
     
      
    }
  
   
    
    render() {
     
      

      return <Tablefilmview/>;
     
  }
}
  
  export default filmviewTable;