import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import {getJWTHeaders} from "../../common/server";
import { Link } from 'react-router-dom';
import {Button, Container, Form, FormGroup, Row, Col, Tab, Nav} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {handleResponse} from "../../common/server";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


export default function Tableuser() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchtxt, setSearchtxt] = useState("");
	
	

	let emptyFilter = {   
		searchtxt: searchtxt, 
		page: currentPage,
		max:perPage,
		sortcol:"surname",
		direction:"asc"		
	  };

	  const [currentFilter, setFilter] = useState(emptyFilter);

	  let emptyUser = {
    
		quantity : 1,
		name:'',
		until: null 
		
	  };

	  const [currentUser, setCurrentUser] = useState(emptyUser);

	  

	 

	const columns = [
		{
		  name: "Id",
		  selector: (row) => row.iduser,
		  sortable: true,
		  sortField:"iduser"
		},
		{
		  name: "Name",
		  selector: (row) => row.name,
		  sortable: true,
		  sortField:"name"
		},
		{
		  name: "Surname",
		  selector: (row) => row.surname,
		  sortable: true,
		  right: true,
		  sortField:"surname"
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			right: true,
			sortField:"email"
		  },
		  {
			name: "Date of born",
			selector: (row) => row.dateofbirth,
			sortable: true,
			right: true,
			sortField:"dateofbirth"
		  },
		  {
			name: "Country",
			selector: (row) => (row.countries ? row.countries.name : "-"),
			sortable: false,
			right: true,
			sortField:"countries.name"
		  },
		  {
			name: "Role",
			selector: (row) => row.admins,
			sortable: false,
			right: true,
			sortField:"admins"
		  },
		{
			name: "Enable",
			selector: (row) => (row.enable ? 'YES' : 'NO'),
			sortable: true,
			right: true,
			sortField:"enable"
		  },
		{
			name: "Action",
		  selector: (row) =>
			<div>		
				<Button size="sm" variant="secondary"  href={"/adduser/" + row.iduser} className='me-2'><FontAwesomeIcon icon={["fal", "edit"]} /></Button>		
				<Button size="sm" variant="danger" onClick={deleteUser} idToDelete={row.iduser}><FontAwesomeIcon icon={["fal", "trash"]} /></Button>
		  	</div>,
		  sortable: false,
		  right: true,
		}
	  ];

	  const deleteUser = (event) => {
		// console.log(event);
	 
	   
	 
		 let iduser = null;
		 if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
		 iduser = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
		 else if (event.target.attributes.getNamedItem('idtodelete'))
		 iduser = event.target.attributes.getNamedItem('idtodelete').value;
		 else
		 console.log(event);

		 confirmAlert({
			title: 'Conferma',
			message: 'Sei sicuro di voler eliminare l\' utente ?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => {
					fetch(process.env.REACT_APP_SERVER_URL+'/streaminguser/'+iduser, {
						method: 'DELETE',
						headers: getJWTHeaders(),
						
					  }).then(
						data => {
						 fetchUser(currentFilter);
						}
					  );
				}
			  },
			  {
				label: 'No',
				onClick: () => alert('Click No')
			  }
			]
		  });
		};
		 
		 
	 
		 
	  

	const fetchUser = async currentFilter => {

		setLoading(true);

   // instead of setTimeout this is where you would handle your API call.
		const options = {
			method: "POST",
			body: JSON.stringify(currentFilter),
			headers: getJWTHeaders()
	  };
	  
	  const response = fetch(process.env.REACT_APP_SERVER_URL+'/streaminguser/table', 
		options
	  ).then(handleResponse)
		.then(function(data){ 
		setData(data.streamingusers);
		setCurrentPage(currentFilter.page);		
		setTotalRows(data.total);
		setFilter(currentFilter);
		setPerPage(currentFilter.max)
		setLoading(false);
	  }).catch(error => 
		{
			console.error(error)
			window.location = '/pleaselogin'
		})
	};

	const handleSort = (column, sortDirection ) => {
		// simulate server sort
		console.log(column, sortDirection);
		currentFilter.direction  = sortDirection;
		currentFilter.sortcol = column.sortField;
		fetchUser(currentFilter);

	};

	const handlePageChange = page => {
		currentFilter.page=page;
		fetchUser(currentFilter);
	};

	const handleSearch = search => {
		console.log(search.target.value);
		setSearchtxt(search.target.value) ;
		currentFilter.searchtxt = search.target.value;
		fetchUser(currentFilter);
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		currentFilter.page=page;
		currentFilter.max=newPerPage;
		fetchUser(currentFilter);
	
	};

	useEffect(() => {
		fetchUser(currentFilter); // fetch page 1 of users
		
	}, []);

	createTheme('solarized', {
		text: {
			primary: '#fff',
			secondary: 'rgba(0,0,0,.54)',
		},
		background: {
			default: '#48486e',
		},
		context: {
			background: '#32324e',
			text: '#FFFFFF',
		},
		divider: {
			default: '#3c3c5d',
		},
		highlightOnHover: {
			default: 'rgba(124, 58, 237, 0.25)',
			text: 'rgba(255, 255, 255, 0.85)',
		},
		striped: {
			default: 'rgba(255, 255, 255, 0.05)',
			text: '#fff',
		},
	});

	return (
		<div>
			<Container fluid={true}>
				<Row>
					<Col><h1>User list</h1></Col>
					<Col>
					<FormGroup>
              <Form.Label for="name">SEARCH in Email, Name, Surname</Form.Label>
              <Form.Control  type="text" name="searchtxt" id="searchtxt" value={searchtxt || ''}
                     onChange={handleSearch} />
            </FormGroup>
			</Col>
					<Col className='text-end'><Button variant="primary" tag={Link} href={"/adduser/new"}>Add user</Button> </Col>
				</Row>
			
				<Row>
					<Col>
						<DataTable
							columns={columns}
							data={data}
							progressPending={loading}
							pagination
							paginationServer
							onSort={handleSort}
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							theme="solarized"
							highlightOnHover
							striped={true}
						/>
					</Col>
				</Row>

			</Container>
        </div>
	);

}