import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import {getJWTHeaders} from "../../common/server";
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {handleResponse} from "../../common/server";



export default function Tablefilmview() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(30);
	const [currentPage, setCurrentPage] = useState(1);

	

	let emptyFilter = {    
		page: currentPage,
		max:perPage,
		sortcol:"tscreation",
		direction:"asc"		
	  };

	  const [currentFilter, setFilter] = useState(emptyFilter);

	const columns = [
		{
			name: "Date",
			selector: (row) => new Intl.DateTimeFormat('it-IT',{ dateStyle: 'long', timeStyle: 'long' }).format(new Date(row.tscreation)),
			sortable: false,
			right: false,
			sortField:"tscreation"
		  },
		{
		  name: "Title",
		  selector: (row) => row.film.internationaltitle,
		  sortable: true,
		  sortField:"film.internationaltitle"
		},
		
		{
		  name: "User",
		  selector: (row) => row.streaminguser.surname + row.streaminguser.name,
		  sortable: false,
		  right: true,
		  sortField:"streaminguser.surname"
		},
		{
			name: "Email",
			selector: (row) => row.streaminguser.email,
			sortable: false,
			right: true,
			sortField:"streaminguser.email"
		  }
		
	  ];

	const fetchUsers = async currentFilter => {
		setLoading(true);

   // instead of setTimeout this is where you would handle your API call.
		const options = {
			method: "POST",
			body: JSON.stringify(currentFilter),
			headers: getJWTHeaders()
	  };
	  
	  const response = fetch(process.env.REACT_APP_SERVER_URL+'/filmview/table', 
		options
	  ).then(handleResponse)
		.then(function(data){ 
		setData(data.filmviews);
		setCurrentPage(currentFilter.page);		
		setTotalRows(data.total);
		setFilter(currentFilter);
		setPerPage(currentFilter.max)
		setLoading(false);
	  }).catch(error => 
		{
			console.error(error)
	 // window.location = '/pleaselogin'
		})
	};

	const handleSort = (column, sortDirection ) => {
		// simulate server sort
		console.log(column, sortDirection);
		currentFilter.direction  = sortDirection;
		currentFilter.sortcol = column.sortField;
		fetchUsers(currentFilter);

	};

	const handlePageChange = page => {
		currentFilter.page=page;
		fetchUsers(currentFilter);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		currentFilter.page=page;
		currentFilter.max=newPerPage;
		fetchUsers(currentFilter);
	
	};

	useEffect(() => {
		fetchUsers(currentFilter); // fetch page 1 of users
		
	}, []);

	createTheme('solarized', {
		text: {
			primary: '#fff',
			secondary: 'rgba(0,0,0,.54)',
		},
		background: {
			default: '#48486e',
		},
		context: {
			background: '#32324e',
			text: '#FFFFFF',
		},
		divider: {
			default: '#3c3c5d',
		},
		highlightOnHover: {
			default: 'rgba(124, 58, 237, 0.25)',
			text: 'rgba(255, 255, 255, 0.85)',
		},
		striped: {
			default: 'rgba(255, 255, 255, 0.05)',
			text: '#fff',
		},
	});

	return (
		<div>
			<Container fluid={true}>
			
				<Row>
					<Col><h1>Filmview list</h1></Col>
					
					<Col className='text-end'><Button variant="primary" tag={Link} href={process.env.REACT_APP_SERVER_URL + "/retrieve/filmviews/export/"}>Export view</Button> </Col>
				
					<Col className='text-end'><Button variant="primary" tag={Link} href={process.env.REACT_APP_SERVER_URL + "/retrieve/filmviews/exportextra/"}>Export view with extra info</Button> </Col>
				
					
				</Row>
				<Row>
					<Col>
						<DataTable
							columns={columns}
							data={data}
							progressPending={loading}
							pagination
							paginationServer
							onSort={handleSort}
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							theme="solarized"
							highlightOnHover
							striped={true}
						/>
					</Col>
				</Row>

			</Container>
        </div>
	);

}