import React, { Component } from 'react';
import {Button, Container, Row, Col, Tab, Nav} from 'react-bootstrap';

import HeaderPlaceholder from "../../images/placeholder-header.png";
import PosterPlaceholder from "../../images/placeholder-poster.png";

import {getJWTHeaders} from "../../common/server";

import { Link } from 'react-router-dom';



class FilmDetail extends Component {

    emptyFilm = {
    
        idfilm: this.props.match.params.idfilm,
        originaltitle:'',
        internationaltitle: '' ,
        genres:{
          idgenres: 0
        }
        
      };

    constructor(props) {
        super(props);
        this.state = { activeTab: '1',
        idfilm:this.props.match.params.idfilm,
        film:this.emptyFilm,
        people: [],
        thingdata: [],
        country: [],
        language :[],
        subtitles :[],
        posterimageURL:PosterPlaceholder,
        headerimageURL:HeaderPlaceholder
    };
    }


    async componentDidMount() {
        const options = {
          method: "GET",
          headers: getJWTHeaders()
    };
          const currentfilm = await (await fetch(process.env.REACT_APP_SERVER_URL+`/film/${this.props.match.params.idfilm}`,options)).json();
         
          this.setState({film:currentfilm });
    
    
          const peopledata = await (await fetch(process.env.REACT_APP_SERVER_URL+'/filmperson/'+this.state.idfilm, options)).json();
        
        this.setState({people: peopledata});

        const thingdata = await (await fetch(process.env.REACT_APP_SERVER_URL+'/filmthing/'+this.state.idfilm, options)).json();
        
        this.setState({thingdata: thingdata});
    
        const kalturafilms = await (await fetch(process.env.REACT_APP_SERVER_URL+`/kalturafilm`,options)).json();
        this.setState({kalturafilm:kalturafilms });

        const country = await (await fetch(process.env.REACT_APP_SERVER_URL+`/filmcountry/film/${this.props.match.params.idfilm}`,options)).json();
        this.setState({country:country });

        const language = await (await fetch(process.env.REACT_APP_SERVER_URL+`/filmlanguage/film/${this.props.match.params.idfilm}`,options)).json();
        this.setState({language:language });

        const subtitles = await (await fetch(process.env.REACT_APP_SERVER_URL+`/filmsubtitles/film/${this.props.match.params.idfilm}`,options)).json();
        this.setState({subtitles:subtitles });

       

		if (this.props.match.params.idfilm !== null) {

			

			this.loadimage(options);

		}

		const imagetypes = await (await fetch(process.env.REACT_APP_SERVER_URL + `/upload/imagetype`, options)).json();
		this.setState({ imagetypes: imagetypes });
      }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

   

	async loadimage(options){

		const mediafile = await (await fetch(process.env.REACT_APP_SERVER_URL + `/retrievefilm/${this.props.match.params.idfilm}`, options)).json();
		console.log(mediafile);
		mediafile.map((data, key) => {
			if (data.mediasource === 0) {
				this.setState({ dvdcoverimageURL: process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia })
			} else if (data.mediasource === 1) {
				this.setState({ posterimageURL: process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia })
			} else if (data.mediasource === 2) {
				this.setState({ headerimageURL: process.env.REACT_APP_SERVER_URL + '/retrieve/image/' + data.idmedia })
			}

		});

	}

  render(){


   

    const thingdata = this.state.thingdata;
   
    const festivallist = thingdata.map(filmthing => {
     
        if(filmthing.thing.role===0) 
        return <li key={filmthing.idfilmthing}> {filmthing.thing.name} </li>
  
      });

      const premierlist = thingdata.map(filmthing => {
      
        if(filmthing.thing.role===1) 
        return <li key={filmthing.idfilmthing}> {filmthing.thing.name} </li>
  
      });

      const companylist = thingdata.map(filmthing => {
      
        if(filmthing.thing.role===2) 
        return <li key={filmthing.idfilmthing}> {filmthing.thing.name} </li>
  
      });

      const people = this.state.people;

    const directorlist = people.map(filmperson => {
      
        if(filmperson.person.role===0) 
        return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} </li>
  
      });
  
      const castlist = people.map(filmperson => {
        
        if(filmperson.person.role===1) 
        return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} </li>
  
      });
  
      const crewlist = people.map(filmperson => {
        
        if(filmperson.person.role===2) 
        return <li key={filmperson.idfilmperson}> {filmperson.person.info}: {filmperson.person.firstname} {filmperson.person.lastname} </li>
  
      });
  
      const producerlist = people.map(filmperson => {
        
        if(filmperson.person.role===3) 
        return <li key={filmperson.idfilmperson}> {filmperson.person.firstname} {filmperson.person.lastname} </li>
  
      });

    const film = this.state.film;
    return(
        <div>
          <Container className='film-detail'>
            <Row>
              <Col xs={12}>
                <h1>Film detail</h1>
              </Col>
              <Col md={6}>
                <p><span>International title</span></p>
                <h5>{film.internationaltitle}</h5>
              </Col>
              <Col md={6}>
                <p><span>Original title</span></p>
                <h5>{film.originaltitle}</h5>
              </Col>
              <Col>
                  <div className='mb-3'>
                      <p><span>Email</span><a href={'mailto:'+film.mail}>{film.mail}</a></p>
                  </div>
              </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <img src={this.state.headerimageURL} className='w-100 mb-3'/>
                   
                </Col>
            </Row>
              <Row>
                  <Col md={9}>
                      <Row>
                          <Col>
                              <div className='mb-5'>
                                  <p><span>Genre</span></p>
                                  <ul>
                                      <li>{film.genres!=null && film.genres.genre}</li>
                                  </ul>
                              </div>
                          </Col>
                          <Col>
                              <div className='mb-5'>
                                  <p><span>Link to booking form</span></p>
                                  <p><a href="linktobookingform">linktobookingform</a></p>
                              </div>
                          </Col>
                      </Row>


                      <Tab.Container defaultActiveKey="ita">
                          <Row className="mt-4 mb-5">
                              <Col xs={12}>
                                  <Nav variant="pills" className="flex-row mb-3">
                                      <Nav.Item>
                                          <Nav.Link eventKey="ita" className="px-5">ITA</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                          <Nav.Link eventKey="eng" className="px-5">ENG</Nav.Link>
                                      </Nav.Item>
                                      <Nav.Item>
                                          <Nav.Link eventKey="fra" className="px-5">FRA</Nav.Link>
                                      </Nav.Item>
                                  </Nav>
                              </Col>
                              <Col xs={12}>
                                  <Tab.Content>
                                      <Tab.Pane eventKey="ita">
                                          <Row md={2}>
                                              <Col>
                                                  <p><span>Short Synopsis - IT</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.shortsynopsis_it }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Synopsis - IT</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.synopsis_it }} className='mb-3'/>
                                              </Col>
                                              <Col>
                                                  <p><span>Director Statement - IT</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.directorstatement_it }}  className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Descrizione del progetto - IT</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.trivia_it }} className='mb-3' />
                                              </Col>
                                          </Row>
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="eng">
                                          <Row md={2}>
                                              <Col>
                                                  <p><span>Short Synopsis - EN</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.shortsynopsis_en }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Synopsis - EN</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.synopsis_en }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Director Statement - EN</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.directorstatement_en }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Project description - EN</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.trivia_en }} className='mb-3' />
                                              </Col>
                                          </Row>
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="fra">
                                          <Row md={2}>
                                              <Col>
                                                  <p><span>Short Synopsis - FR</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.shortsynopsis_fr }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Synopsis - FR</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.synopsis_fr }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Director Statement - FR</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.directorstatement_fr }} className='mb-3' />
                                              </Col>
                                              <Col>
                                                  <p><span>Description du projet - FR</span></p>
                                                  <p dangerouslySetInnerHTML={{ __html: film.directorstatement_fr }} className='mb-3' />
                                              </Col>
                                          </Row>
                                      </Tab.Pane>
                                  </Tab.Content>
                              </Col>
                          </Row>
                      </Tab.Container>

                      <div className='mb-3'>
                          <p><span>Regia</span></p>
                          <ul>
                              {directorlist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Producer</span></p>
                          <ul>
                              {producerlist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Cast</span></p>
                          <ul>
                              {castlist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Crew</span> </p>
                          <ul>
                              {crewlist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Festivals</span> </p>
                          <ul>
                          {festivallist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Premier</span> </p>
                          <ul>
                          {premierlist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Company</span> </p>
                          <ul>
                          {companylist}
                          </ul>
                      </div>
                      <div className='mb-3'>
                          <p><span>Trailer link:</span><a href={film.trailerlink}>{film.trailerlink}</a></p>
                      </div>
                  </Col>
                  <Col md={3}>
                      <img src={this.state.posterimageURL} className='w-100 mb-3'/>
                          <Tab.Container defaultActiveKey="itaAge">
                              <Row className="mt-4 mb-5">
                                  <Col xs={12}>
                                      <Nav variant="pills" className="flex-row mb-3">
                                          <Nav.Item>
                                              <Nav.Link eventKey="itaAge" className="px-2 py-1">IT</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                              <Nav.Link eventKey="engAge" className="px-2 py-1">EN</Nav.Link>
                                          </Nav.Item>
                                          <Nav.Item>
                                              <Nav.Link eventKey="fraAge" className="px-2 py-1">FR</Nav.Link>
                                          </Nav.Item>
                                      </Nav>
                                  </Col>
                                  <Col xs={12}>
                                      <Tab.Content>
                                          <Tab.Pane eventKey="itaAge">
                                              <p><span> Recommended age- IT</span></p>
                                              <p>Pubblico</p>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="engAge">
                                              <p><span> Recommended age- EN</span></p>
                                              <p>People</p>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="fraAge">
                                              <p><span> Recommended age- FR</span></p>
                                              <p>Public</p>
                                          </Tab.Pane>
                                      </Tab.Content>
                                  </Col>
                              </Row>
                          </Tab.Container>

                      <div className='mb-5'>
                          <p><span>Year</span> {film.year}</p>
                      </div>

                      <div className='mb-5'>
                          <p><span>Country</span> { this.state.country.map(current => { return <li key={current.country.code}> {current.country.name} </li>}) }</p>
                      </div>
                      <div className='mb-5'>
                          <p><span>Original Language</span> { this.state.language.map(current => { return <li key={current.language.idlanguage}> {current.language.name} </li>}) }</p>
                      </div>
                      <div className='mb-5'>
                          <p><span>Subtitle</span> { this.state.subtitles.map(current => { return <li key={current.idlanguage}> {current.language.name} </li>}) }</p>

                      </div>
                      <div className='mb-5'>
                          <p><span>Runtime</span> {film.runtime}</p>
                      </div>
                      <div className='mb-5'>
                          <p><span>Resolution</span> {film.playbackresolution}</p>
                      </div>
                  </Col>
              </Row>
              <Row className='mt-5 border-top border-secondary pt-4'>
                  <Col md={12}>
                      <h6>Extra content</h6>
                  </Col>
              </Row>
              <Row>
                  <Col>
                      <Button variant="primary" tag={Link} href={"/addfilm/"+film.idfilm} className='me-2 px-4'>Edit Main Information</Button>{' '}
                      <Button variant="info" tag={Link} href={"/addfilmextra/"+film.idfilm} className='me-2 px-4'>Edit Extra Information</Button>{' '}
                      <Button variant="success" tag={Link} href={"/uploadimage/"+film.idfilm} className='me-2 px-4'>Edit Images</Button>{' '}
                      <Button variant="secondary" tag={Link} href="/filmtable" className='px-4'>Film list</Button>
                  </Col>
              </Row>
          </Container>
        </div>
    );
  }
}

export default FilmDetail;