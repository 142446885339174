import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import {getJWTHeaders} from "../../common/server";
import { Link } from 'react-router-dom';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {handleResponse} from "../../common/server";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


export default function Tablefilm() {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(30);
	const [currentPage, setCurrentPage] = useState(1);

	

	let emptyFilter = {    
		page: currentPage,
		max:perPage,
		sortcol:"internationaltitle",
		direction:"asc"		
	  };

	  const [currentFilter, setFilter] = useState(emptyFilter);

	const columns = [
		{
		  name: "Title",
		  selector: (row) => row.internationaltitle,
		  sortable: true,
		  sortField:"internationaltitle"
		},
		
		{
		  name: "Year",
		  selector: (row) => row.year,
		  sortable: true,
		  right: true,
		  sortField:"year"
		},
		{
			name: "Genres",
			selector: (row) => row.genres!=null && row.genres.genre,
			sortable: false,
			right: true,
			sortField:"genres.genre"
		  },
		  {
			name: "Kaltura",
			selector: (row) => (row.kalturaid ? row.kalturaid :  (row.filmtype===3) ? <span className='text-warning'>COVER</span> : <span className='text-danger fw-bold'>ASSENTE</span>  ),
			sortable: true,
			right: true,
			sortField:"kalturaid"
		  },
		  {
			  name: "Price",
			  selector: (row) => (row.idcontainer ? <span className='text-warning'>PACK</span> : (row.price!=null ? row.price : "-")),
			  sortable: true,
			  right: true,
			  sortField:"price"
			},
			{
				name: "Types",
				selector: (row) => row.filmtypes,
				sortable: true,
				right: true,
				sortField:"filmtype"
			  },

			
		{
			name: "Action",
		  selector: (row) =>
			<div>
				<Button size="sm" variant="primary" href={"/preview/" + row.idfilm} className='me-2'><FontAwesomeIcon icon={["fal", "film"]} /></Button>
				<Button size="sm" variant="primary" href={"/filmdetail/" + row.idfilm} className='me-2'><FontAwesomeIcon icon={["fal", "info-circle"]} /></Button>
				<Button size="sm" variant="secondary"  href={"/addfilm/" + row.idfilm} className='me-2'><FontAwesomeIcon icon={["fal", "edit"]} /></Button>
				<Button size="sm" variant="info"  href={"/addfilmextra/" + row.idfilm} className='me-2'><FontAwesomeIcon icon={["fal", "plus-circle"]} /></Button>
				<Button size="sm" variant="success"  href={"/uploadimage/" + row.idfilm} className='me-2'><FontAwesomeIcon icon={["fal", "image-polaroid"]} /></Button>
				<Button size="sm" variant="danger" onClick={deletefilm} idToDelete={row.idfilm}><FontAwesomeIcon icon={["fal", "trash"]} /></Button>
		  	</div>,
		  sortable: false,
		  right: true,
		  width: '300px'
		}
	  ];

	  const deletefilm = (event) => {
		// console.log(event);
	 
	   
	 
		 let idfilm = null;
		 if(event.target.parentElement.attributes.getNamedItem('idtodelete'))
		 idfilm = event.target.parentElement.attributes.getNamedItem('idtodelete').value;
		 else if (event.target.attributes.getNamedItem('idtodelete'))
		 idfilm = event.target.attributes.getNamedItem('idtodelete').value;
		 else
		 console.log(event);

		 confirmAlert({
			title: 'Conferma',
			message: 'Sei sicuro di voler eliminare il film ?',
			buttons: [
			  {
				label: 'Yes',
				onClick: () => {
					fetch(process.env.REACT_APP_SERVER_URL+'/film/'+idfilm, {
						method: 'DELETE',
						headers: getJWTHeaders(),
						
					  }).then(
						data => {
							fetchUsers(currentFilter);
						}
					  );
				}
			  },
			  {
				label: 'No',
				onClick: () => alert('Click No')
			  }
			]
		  });
		};

	const fetchUsers = async currentFilter => {
		setLoading(true);

   // instead of setTimeout this is where you would handle your API call.
		const options = {
			method: "POST",
			body: JSON.stringify(currentFilter),
			headers: getJWTHeaders()
	  };
	  
	  const response = fetch(process.env.REACT_APP_SERVER_URL+'/film/table', 
		options
	  ).then(handleResponse)
		.then(function(data){ 
		setData(data.films);
		setCurrentPage(currentFilter.page);		
		setTotalRows(data.total);
		setFilter(currentFilter);
		setPerPage(currentFilter.max)
		setLoading(false);
	  }).catch(error => 
		{
			console.error(error)
	  window.location = '/pleaselogin'
		})
	};

	const handleSort = (column, sortDirection ) => {
		// simulate server sort
		console.log(column, sortDirection);
		currentFilter.direction  = sortDirection;
		currentFilter.sortcol = column.sortField;
		fetchUsers(currentFilter);

	};

	const handlePageChange = page => {
		currentFilter.page=page;
		fetchUsers(currentFilter);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		currentFilter.page=page;
		currentFilter.max=newPerPage;
		fetchUsers(currentFilter);
	
	};

	useEffect(() => {
		fetchUsers(currentFilter); // fetch page 1 of users
		
	}, []);

	createTheme('solarized', {
		text: {
			primary: '#fff',
			secondary: 'rgba(0,0,0,.54)',
		},
		background: {
			default: '#48486e',
		},
		context: {
			background: '#32324e',
			text: '#FFFFFF',
		},
		divider: {
			default: '#3c3c5d',
		},
		highlightOnHover: {
			default: 'rgba(124, 58, 237, 0.25)',
			text: 'rgba(255, 255, 255, 0.85)',
		},
		striped: {
			default: 'rgba(255, 255, 255, 0.05)',
			text: '#fff',
		},
	});

	return (
		<div>
			<Container fluid={true}>
			
				<Row>
					<Col><h1>Film list</h1></Col>
					<Col className='text-end'><Button variant="primary" tag={Link} href={process.env.REACT_APP_SERVER_URL + "/retrieve/filmpayments/export/"}>Export payments</Button> </Col>
					<Col className='text-end'><Button variant="primary" tag={Link} href={process.env.REACT_APP_SERVER_URL + "/retrieve/filmviews/export/"}>Export view</Button> </Col>
				
					<Col className='text-end'><Button variant="primary" tag={Link} href={"/addfilm/new"}>Add film</Button> </Col>
				</Row>
				<Row>
					<Col>
						<DataTable

							columns={columns}
							data={data}
							progressPending={loading}
							pagination
							paginationServer
							onSort={handleSort}
							paginationTotalRows={totalRows}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							theme="solarized"
							highlightOnHover
							striped={true}
						/>
					</Col>
				</Row>

			</Container>
        </div>
	);

}