import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  
   Navbar,
  
   
   NavDropdown
} from 'react-bootstrap';

import {deleteJWT} from "../../../common/server";



export default class Header extends React.Component{ 
  

   logout(){
      deleteJWT();
      localStorage.clear();
      window.location.href = '/';
   }

   render(){ 
      return( 
        <div>
           <Navbar variant="transparent" dark="true" expand="lg" className="navbar-absolute">
              <div className='container-fluid'>
                 <div className='navbar-wrapper flex justify-content-end w-100'>
                    <NavDropdown title={<FontAwesomeIcon icon={["fal", "user"]} />} className="dropdown-user">
                       <NavDropdown.Item href="/login">Login</NavDropdown.Item>
                       <NavDropdown.Item onClick={this.logout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                 </div>
              </div>
           </Navbar>
        </div>
      ); 
   }
}