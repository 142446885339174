import { Route, Switch} from 'react-router-dom';
import LoginTemplate from "./mainLayout/template/loginTemplate";
import React, { Component } from 'react';
import Login from "./login/login";





class LoginPage extends Component {
 
  
    render() {
  
       return (

<LoginTemplate>
<Switch>
   
    <Route exact path='/login' component={Login}/>
    
   
</Switch>
</LoginTemplate>

);
}
}

export default LoginPage;