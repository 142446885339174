import {Route, Switch} from 'react-router-dom';
import MainTemplate from "./mainLayout/template/mainTemplate";
import React, { Component } from 'react';
import Home from "./home/home";

import Films from "./film/filmlist";
import FilmsTable from "./film/filmtable";
import FilmsTableView from "./film/filmtableview";
import Addfilm from './film/addfilm';
import Addfilmextra from './film/addfilmextra';
import UploadImage from './film/uploadimage';
import FilmDetail from './film/filmdetail';
import Preview from './film/preview';
import CouponTable from "./coupon/coupontable";
import UserTable from "./user/usertable";
import PleaseLogin from "./login/pleaselogin";
import Adduser from "./user/adduser";

class MainPage extends Component {
  
    render() {
  
       return (

<MainTemplate>
               <Switch>
                   <Route  exact path='/' component={Home}/>                  
                   <Route  exact path='/films' component={Films}/>
                   <Route  exact path='/filmtable' component={FilmsTable}/>
                   <Route  exact path='/filmtableview' component={FilmsTableView}/>
                   <Route  exact path='/addfilm/:idfilm' component={Addfilm}/>
                   <Route  exact path='/addfilmextra/:idfilm' component={Addfilmextra}/>
                   <Route  exact path='/uploadimage/:idfilm' component={UploadImage}/>
                   <Route  exact path='/filmdetail/:idfilm' component={FilmDetail}/>
                   <Route  exact path='/preview/:idfilm' component={Preview}/>
                   <Route  exact path='/coupontable' component={CouponTable}/>
                   <Route  exact path='/usertable' component={UserTable}/>
                   <Route  exact path='/pleaselogin' component={PleaseLogin}/>
                   <Route  exact path='/adduser/:iduser' component={Adduser}/>
               </Switch>
</MainTemplate>

);
}
}

export default MainPage;